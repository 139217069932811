import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import config from "../../config";
import { Auth } from 'aws-amplify';

class Account extends Component {

  constructor( props ) {
    super( props );

    this.state = {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      email: "",
      isLoading: false,
      error: "",
      success: ""
    };
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser()
    .then( data => this.setState({ isLoading: false, email: data.attributes.email }) )
    .catch( error => this.setState({ isLoading: false, success: "", error: error.message }) );
  }

  handleChange = event => {
    this.setState({ [ event.target.id ]: event.target.value });
  }

  validateForm() {
    return this.state.oldPassword.length > 7 && this.state.newPassword.length > 7 && this.state.newPassword === this.state.newPasswordConfirm;
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    Auth.currentAuthenticatedUser()
      .then( user => {
        return Auth.changePassword( user, this.state.oldPassword, this.state.newPassword );
      })
      .then( data => this.setState({ isLoading: false, success: "Changed password", error: "" }) )
      .catch( error => this.setState({ isLoading: false, success: "", error: error.message }) );
  }

  render() {
    config.DEBUG && console.log( "ACCOUNT RENDER" );
    return (
      <div className="widthmenu-main-content">
        <div className="widthmenu-page-section">
          <div className="col-md-12">
            <h3 className="lead text-center"> Account </h3>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <form name="account-info" className="account-form">
      		    <hr></hr>
              <h4>Email address:</h4> <input type="email" name="email-address" value={this.state.email} disabled></input>
              <h4>Account type: </h4> <input type="text" name="plan" value="FREE" disabled></input>
              <LoaderButton
                block
                className="button big"
                disabled={true}
                type="submit"
                isLoading={false}
                text="Select Plan"
              />
      	    </form>

            <form name="change-pass" className="account-form" onSubmit={this.handleConfirmationSubmit}>
              <hr></hr>
              <h4> Change Password </h4>
           	  <h5>*</h5>
                <input type="password" name="current-pass" id="oldPassword" className="pass" placeholder="Current Password" value={ this.state.oldPassword } onChange={ this.handleChange } required></input>
                <h5>*</h5>
                <input type="password" name="new-pass" id="newPassword" className="pass" placeholder="New Password" value={ this.state.newPassword } onChange={ this.handleChange } required></input>
                <h5>*</h5>
                <input type="password" name="new-pass" id="newPasswordConfirm" className="pass" placeholder="Retype New Password" value={ this.state.newPasswordConfirm } onChange={ this.handleChange } required></input>
                <LoaderButton
                  block
                  className="button big"
                  disabled={!this.validateForm()}
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Change Password"
                />
                <div className="msgError" style={{marginTop: "15px"}}>
                  { this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert> }
                  { this.state.success && <Alert bsStyle="success">{this.state.success}</Alert> }
                </div>
            </form>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <form name="monetarization" action="#" method="post" className="account-form">
              <hr></hr>
              <h3> Monetarization </h3>
           	  <br></br>
              <p className="check-txt">Activate </p><input type="checkbox" name="activate" value="active" className="check" disabled></input>
              <p className="check-txt">Your current credit balance is: </p> <p> $ </p>
              <LoaderButton
                block
                className="button big"
                disabled={true}
                type="submit"
                isLoading={false}
                text="Buy Credits"
              />
            </form>
         	  <form name="privacy" action="#" method="post" className="account-form">
              <hr></hr>
              <h3> Privacy </h3>
           	  <br></br>
              <p className="check-txt">Share with all users my videos </p><input type="checkbox" name="activate" value="active" className="check" disabled></input>
              <LoaderButton
                block
                className="button big"
                disabled={true}
                type="submit"
                isLoading={false}
                text="Desactivate my account"
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
