import React, { Component } from 'react';
import $ from 'jquery';
import { select, selectAll, event } from 'd3-selection';
import { axisTop } from 'd3-axis';
import { scaleTime } from 'd3-scale';
import { timeFormat } from 'd3-time-format';
import { brushX } from 'd3-brush';
import { drag } from 'd3-drag';
import config from "../../config";


class SimpleTimeline extends Component {
  constructor( props ){
    super( props );
    this.createSimpleTimeline = this.createSimpleTimeline.bind( this );
    config.DEBUG && console.log("SIMPLETIMELINE CONSTRUCTOR");
  }

  componentDidMount() {
    this.createSimpleTimeline();
    config.DEBUG && console.log( "SIMPLETIMELINE COMPONENTDIDMOUNT" );
  }

  componentDidUpdate() {
    config.DEBUG && console.log( "SIMPLETIMELINE COMPONENTDIDUPDATE");
    // this.createSimpleTimeline();
  }

	createSimpleTimeline() {
		const node = this.node;
		const size = this.props.size;
		const input = this.props.data;
		const handleOutputTimeline = this.props.output;
		/* Set width and height of chart, height of Timeline bars */
		let margin = {top: 30, right: 20, bottom: 30, left: 20},
			width = size.width - margin.left - margin.right,
			height = 120 - margin.top - margin.bottom,
			barHeight = 60;

		/* draw the svg */
		let chart = select( node ).append( "svg" )
			.attr( "preserveAspectRatio", "xMinYMin meet" )
			.attr( "viewBox", "0 0 " + (width + margin.left + margin.right) + " " + (height + margin.top + margin.bottom) )
			.append( 'g' )
			.attr( "transform", "translate(" + margin.left + "," + margin.top + ")" );

    /* Input Data, Timeline period : Default value is 1 hour. */
		let periodTime = 60*60*1000;
    /* Min and Max Timestamp for X axis domain	*/
    // const currentTime = new Date().getTime();
    const currentTime = input[input.length-1].end*1000;
		let minX = currentTime - periodTime,
			maxX = currentTime + periodTime;

		/* draw the x axis */
		let xScale = scaleTime().domain( [minX, maxX] ).range( [margin.left, width - margin.right] );
		let xAxis = axisTop( xScale ).ticks(4).tickFormat( timeFormat( "%H:%M" ) );

    /* Add x axis into chart */
		chart.append( "g" ).attr( "class", "axis axis-x" );
		chart.select( '.axis-x' ).attr( "transform", "translate(0,0)" ).call( xAxis );

    /* draw border to the svg */
		let borderPath = chart.append("rect")
			.attr("x", margin.left)
			.attr("y", 0)
			.attr("height", height)
			.attr("width", width - margin.left - margin.right)
			.style("stroke", "black")
			.style("fill", "none")
			.style("stroke-width", 1);

		/* Add time bars to the chart */
		let span = chart.append("g").attr("transform", "translate(0,0)")

    let formatTime = timeFormat("%B %d, %Y");

		/* add start timestamp to bottom of chart */
		let BdY = chart.append("text")
			.attr("x", margin.left)
			.attr("y", height + margin.top - 10)
			.text( function(){ return formatTime(minX); });

		/* define timeline selector and add event */
		let brush = brushX()
			.extent([[margin.left, 0], [width - margin.right, height]])
			.on("start brush", brushed)
      .on("end", brushedEnd);

		let gBrush = chart.append("g")
			.attr("class", "brush")
			.call(brush)
		/* timeline selector left and right circle button style */
		let brushResizePath = function(d) {
			let e = +(d.type === "e"),
				x = e ? 1 : -1,
				y = height / 2;
			return "M" + (2.5 * x) + "," + y + "A6,6 0 0 " + e + " " + (13.5 * x) + "," + (y + 6) + "V" + (2 * y - 6) + "A6,6 0 0 " + e + " " + (2.5 * x) + "," + (2 * y) + "Z" + "M" + (5.5 * x) + "," + (y + 8) + "V" + (2 * y - 8) + "M" + (8.5 * x) + "," + (y + 8) + "V" + (2 * y - 8);
		}

    /* set value for zoom and shift and brush */
		let newMinX = minX,							// new min timestamp for x axis domain setting when timeline period change
			newMaxX = maxX,							// new max timestamp for x axis domain setting when timeline period change
			zoomScale = (newMaxX - newMinX) / 4, 	// zoom scale to zoom+ or zoom- : the double or half of the current value
			shiftScale = (newMaxX - newMinX) / 2;   // scale to move period back or forward : 50% of the current view

    /*  Time selection start timestamp and Time selection end timestamp
		By default this will be 1 minute before or after the initial timeline centre timestamp.	*/
    let selectMinX = newMaxX - shiftScale - 60*5000,
			selectMaxX = newMaxX - shiftScale + 60*5000;

    handleOutputTimeline( { start: selectMinX/1000, end: selectMaxX/1000 } );

		/* add timeline selector left and right circle button */
		let handle = gBrush.selectAll(".handle--custom")
			.data([{type: "w"}, {type: "e"}])
			.enter().append("path")
			.attr("class", "handle--custom")
			.attr("stroke", "black")
			.style("stroke-width", 2)
			.attr("cursor", "ew-resize")
			.attr("d", brushResizePath);
		/* move to timeline center the timeline selector */
		gBrush.call(brush.move, [selectMinX, selectMaxX].map(xScale));

		// removes crosshair cursor
		selectAll('.brush>.overlay').remove();

		/* add drag events in chart */
		select("svg").call(drag()
			.on("start", () => {} )
			.on("drag", dragged)
			.on("end", dragended));

		/* ajax datas call to draw the initial chart */
		// $.ajax({
		// 	url: "api.php?",
		// 	type: "GET",
		// 	data:{ "sdate" : new Date().getTime()-periodTime, "edate" : new Date().getTime() },
		// 	success: function(result){
		// 		input = JSON.parse(result);
		// drawChart(input);
		// 	}
		// });

    drawChart(input);

		/* function to draw the initial chart */
    function drawChart(input){
      input.forEach(function(d){
        d.start = d.start*1000;
        d.end = d.end*1000;
      })
      span.selectAll('rect')
  			.data(input)
  		  .enter().append('rect')
  			.attr("class", 'chart-span')
  			.attr('x', function(d, i) {
  				if(d.start > newMaxX){
  					return  xScale(newMaxX);
  				}else{
  					if(d.start < newMinX){
  						return  xScale(newMinX);
  					}else{
  						return  xScale(d.start);
  					}
  				}
  			})
  			.attr('y', height - barHeight)
  			.attr('width', function(d, i) {
  				if(d.start > newMaxX){
  					return 0;
  				}else{
  					if(d.start < newMinX){
  						if(d.end > newMinX){
  							return d.end < newMaxX ? xScale(d.end) - xScale(newMinX) : xScale(newMaxX) - xScale(newMinX);
  						}else{
  							return 0;
  						}
  					}else{
  						return d.end > newMaxX ? xScale(newMaxX) - xScale(d.start) : xScale(d.end) - xScale(d.start);
  					}
  				}
  			})
  			.attr('height', barHeight)
  			.style("fill", function(d){ return d.state === 1 ? "#41D31A" : "#E8360F" })
    }

    let dragstartedTime, draggedTime, dragScale = 60*1000;

		/* chart drag start event */
		function dragstarted(d) {
			dragstartedTime = new Date(xScale.invert(event.x)).getTime();
		}

		/* chart dragging event */
		function dragged(d) {
			draggedTime = new Date(xScale.invert(event.x)).getTime();
			dragScale = (newMaxX - newMinX)/60;

			if((draggedTime - dragstartedTime) > 0){
				newMinX = newMinX - dragScale;
				newMaxX = newMaxX - dragScale;

				BdY.text( function(){ return formatTime(newMinX); });

				dragChart(newMinX, newMaxX);
				gBrush.call(brush.move, [selectMinX, selectMaxX].map(xScale));
			}else{
				newMinX = newMinX + dragScale;
				newMaxX = newMaxX + dragScale;

				BdY.text( function(){ return formatTime(newMinX); });

				dragChart(newMinX, newMaxX);
				gBrush.call(brush.move, [selectMinX, selectMaxX].map(xScale));
			}
		}

		/* chart drag end event */
		function dragended(d) {
			let dragendedTime = new Date(xScale.invert(event.x)).getTime();

			// $.ajax({
			// 	url: "api.php?",
			// 	type: "GET",
			// 	data:{ "sdate" : newMinX, "edate" : newMaxX },
			// 	success: function(result){
			// 		input = JSON.parse(result);
      //
			BdY.text( function(){ return formatTime(newMinX); });
      //
			selectAll(".chart-span").remove();
			updateChart(newMinX, newMaxX);
			gBrush.call(brush.move, [selectMinX, selectMaxX].map(xScale));
      //
			// 	}
			// });
		}

		/* chart timeline period selector event */
		function brushed() {
			//if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom
			let s = event.selection || xScale.range();

			if (s == null) {
				handle.attr("display", "none");
			} else {
				handle.attr("display", null).attr("transform", function(d, i) { return "translate(" + [ s[i], - height / 4] + ")"; });

				let selectTimeRange = s.map(xScale.invert, xScale);
				selectMinX = selectTimeRange[0];
				selectMaxX = selectTimeRange[1];
      }
		}

		/* chart timeline period selector event */
		function brushedEnd() {
			//if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom
			let s = event.selection || xScale.range();

			if (s == null) {
				handle.attr("display", "none");
			} else {
				handle.attr("display", null).attr("transform", function(d, i) { return "translate(" + [ s[i], - height / 4] + ")"; });

				let selectTimeRange = s.map(xScale.invert, xScale);
				selectMinX = selectTimeRange[0];
				selectMaxX = selectTimeRange[1];
        handleOutputTimeline( { start: selectMinX/1000, end: selectMaxX/1000 } );
      }
		}


    /* zoom+ */
    $("#zoomin").on("click", function(){
			if((newMinX + zoomScale) < (newMaxX - zoomScale)) {
				newMinX = newMinX + zoomScale;
				newMaxX = newMaxX - zoomScale;

				// $.ajax({
				// 	url: "api.php?",
				// 	type: "GET",
				// 	data:{ "sdate" : newMinX, "edate" : newMaxX },
				// 	success: function(result){
				// 		input = JSON.parse(result);
						BdY.text( function(){ return formatTime(newMinX); });

						selectAll(".chart-span").remove();
						updateChart(newMinX, newMaxX);
						gBrush.call(brush.move, [selectMinX, selectMaxX].map(xScale));

						zoomScale = (newMaxX - newMinX) / 4;
						shiftScale = (newMaxX - newMinX) / 2;

					// }
				// });
			}
		})

		/* zoom- */
		$("#zoomout").on("click", function(){
			newMinX = newMinX - zoomScale;
			newMaxX = newMaxX + zoomScale;

			// $.ajax({
			// 	url: "api.php?",
			// 	type: "GET",
			// 	data:{ "sdate" : newMinX, "edate" : newMaxX },
			// 	success: function(result){
			// 		input = JSON.parse(result);
      //
					BdY.text( function(){ return formatTime(newMinX); });

					selectAll(".chart-span").remove();
					updateChart(newMinX, newMaxX);
					gBrush.call(brush.move, [selectMinX, selectMaxX].map(xScale));

					zoomScale = (newMaxX - newMinX) / 4;
					shiftScale = (newMaxX - newMinX) / 2;

				// }
			// });
		})

		/* reset */
		$("#reset").on("click", function(){
			newMinX = minX;	newMaxX = maxX;

			// $.ajax({
			// 	url: "api.php?",
			// 	type: "GET",
			// 	data:{ "sdate" : newMinX, "edate" : newMaxX },
			// 	success: function(result){
			// 		input = JSON.parse(result);

					BdY.text( function(){ return formatTime(newMinX); });

					selectAll(".chart-span").remove();
					updateChart(newMinX, newMaxX);

					zoomScale = (newMaxX - newMinX) / 4;
					shiftScale = (newMaxX - newMinX) / 2;

					selectMinX = newMaxX - shiftScale - 60*1000;
					selectMaxX = newMaxX - shiftScale + 60*1000;
          handleOutputTimeline( { start: selectMinX/1000, end: selectMaxX/1000 } );
					gBrush.call(brush.move, [selectMinX, selectMaxX].map(xScale));

				// }
			// });
		})

		/* Shift period left button click */
		$("#shiftLeft").on("click", function(){
			newMinX = newMinX - shiftScale;
			newMaxX = newMaxX - shiftScale;

			// $.ajax({
			// 	url: "api.php?",
			// 	type: "GET",
			// 	data:{ "sdate" : newMinX, "edate" : newMaxX },
			// 	success: function(result){
			// 		input = JSON.parse(result);
      //
					BdY.text( function(){ return formatTime(newMinX); });

					selectAll(".chart-span").remove();
					updateChart(newMinX, newMaxX);
					gBrush.call(brush.move, [selectMinX, selectMaxX].map(xScale));

				// }
			// });
		})

		/* Shift period right button click */
		$("#shiftRight").on("click", function(){
			if((newMaxX + shiftScale) <= maxX) {
				newMinX = newMinX + shiftScale;
				newMaxX = newMaxX + shiftScale;

				// $.ajax({
				// 	url: "api.php?",
				// 	type: "GET",
				// 	data:{ "sdate" : newMinX, "edate" : newMaxX },
				// 	success: function(result){
				// 		input = JSON.parse(result);

						BdY.text( function(){ return formatTime(newMinX); });

						selectAll(".chart-span").remove();
						updateChart(newMinX, newMaxX);
						gBrush.call(brush.move, [selectMinX, selectMaxX].map(xScale));

					}
				// });
			// }
		})

    /* Function to convert Timestamp to Date */
		function timeConverter(UNIX_timestamp){
			var a = new Date(UNIX_timestamp);
			var year = a.getFullYear();
			var month = a.getMonth() + 1;
			month = month.toString().length == 1 ? "0"+month : month;
			var date = a.getDate();
			date = date.toString().length == 1 ? "0"+date : date;
			var hour = a.getHours();
			hour = hour.toString().length == 1 ? "0"+hour : hour;
			var min = a.getMinutes();
			min = min.toString().length == 1 ? "0"+min : min;
			var sec = a.getSeconds();
			sec = sec.toString().length == 1 ? "0"+sec : sec;

			var time = year + '-' + month + '-' + date + 'T' + hour + ':' + min + ':' + sec ;
			return time;
		}

		/* Save selection */
		$("#getbrush").on("click", function(){
			// var outMinX = timeConverter(selectMinX);
			// var outMaxX = timeConverter(selectMaxX);
      handleOutputTimeline( { start: selectMinX/1000, end: selectMaxX/1000 } );

		})


    /* redraw the chart for zoom and shift and brush */
		function updateChart(newMinX, newMaxX){
			xScale.domain([newMinX, newMaxX]);
			chart.select(".axis-x").call(xAxis);

			span.selectAll('rect')
				.data(input)
				.enter().append('rect')
				.attr("class", 'chart-span')
				.attr('x', function(d, i) {
					if(d.start > newMaxX){
						return  xScale(newMaxX);
					}else{
						if(d.start < newMinX){
							return  xScale(newMinX);
						}else{
							return  xScale(d.start);
						}
					}
				})
				.attr('y', height - barHeight)
				.attr('width', function(d, i) {
					if(d.start > newMaxX){
						return 0;
					}else{
						if(d.start < newMinX){
							if(d.end > newMinX){
								return d.end < newMaxX ? xScale(d.end) - xScale(newMinX) : xScale(newMaxX) - xScale(newMinX);
							}else{
								return 0;
							}
						}else{
							return d.end > newMaxX ? xScale(newMaxX) - xScale(d.start) : xScale(d.end) - xScale(d.start);
						}
					}
				})
				.attr('height', barHeight)
				.style("fill", function(d){ return d.state === 1 ? "#41D31A" : "#E8360F" });
		}

		/* function to drag the chart when drag event */
		function dragChart(newMinX, newMaxX){
			xScale.domain([newMinX, newMaxX]);
			chart.select(".axis-x").call(xAxis);

			input.forEach(function(d){
				d.start = d.start*1000;
				d.end = d.end*1000;
			})
			selectAll('.chart-span')
				.attr('x', function(d, i) {
					if(d.start > newMaxX){
						return  xScale(newMaxX);
					}else{
						if(d.start < newMinX){
							return  xScale(newMinX);
						}else{
							return  xScale(d.start);
						}
					}
				})
				.attr('y', height - barHeight)
				.attr('width', function(d, i) {
					if(d.start > newMaxX){
						return 0;
					}else{
						if(d.start < newMinX){
							if(d.end > newMinX){
								return d.end < newMaxX ? xScale(d.end) - xScale(newMinX) : xScale(newMaxX) - xScale(newMinX);
							}else{
								return 0;
							}
						}else{
							return d.end > newMaxX ? xScale(newMaxX) - xScale(d.start) : xScale(d.end) - xScale(d.start);
						}
					}
				})
				.attr('height', barHeight)
				.style("fill", function(d){ return d.state === 1 ? "#41D31A" : "#E8360F" });
		}


	}

	render() {
	    return (
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-12">

              <svg ref={node => this.node = node}
                width={'100%'} height={'100%'}
              />
              <div className="text-center">
                <a type="button" id="shiftLeft" className="btn btn-light p-1">
                  <img src="img/chevron-left.svg"></img>
                </a>
                <a type="button" id="shiftRight" className="btn btn-light p-1">
                  <img src="img/chevron-right.svg"></img>
                </a>
                <a type="button" id="zoomin" className="btn btn-light py-1">
                Zoom +
                </a>
                <a type="button" id="zoomout" className="btn btn-light py-1">
                Zoom -
                </a>
                <a type="button" id="reset" className="btn btn-light ml-5 py-1">
                Reset
                </a>
              </div>
      		  </div>
      		</div>
      	</div>
			);
  }
}
export default SimpleTimeline;
