/* input:
/    int >=0: represent a quadrant of one degree per one degree.
/  output:
/    Array(2) = [ latitude, longitude ] represent the center of quadrant.
/    return [] sii input is not valid
*/
export const getLatLngFromQuadrant = quadrant => {
  if ( quadrant < 0 )
    return [];
  const lng = parseInt( quadrant/1000 )-180;
  const lat = Math.round( ( ( quadrant/1000 )%1 )*1000 )-90;
  if ( lat >= 0 && lng >= 0 )
    return [ lat+0.5, lng+0.5 ];
  else if ( lat >= 0 && lng < 0 )
    return [ lat+0.5, lng-0.5 ];
  else if ( lat < 0 && lng < 0 )
    return [ lat-0.5, lng-0.5 ];
  else
    return [ lat-0.5, lng+0.5 ];
};


export const changedQuadrants = ( prevBounds, currentBounds ) => {
  if ( !prevBounds || !currentBounds )
    return true;
  return ( parseInt( prevBounds._northEast.lat ) !== parseInt( currentBounds._northEast.lat ) ) ||
    ( parseInt( prevBounds._northEast.lng ) !== parseInt( currentBounds._northEast.lng ) );
};

const quadrantCalculation = ( lat, lng ) => {
  return parseInt( lat ) + 90 + ( parseInt( lng ) + 180 ) * 1000;
};

export const getQuadrantsFromBounds = bounds => {
  if ( !bounds )
    return false;
  const quadrants = new Set();
  quadrants.add( quadrantCalculation( bounds._southWest.lat, bounds._southWest.lng ) );
  quadrants.add( quadrantCalculation( bounds._southWest.lat, bounds._northEast.lng ) );
  quadrants.add( quadrantCalculation( bounds._northEast.lat, bounds._northEast.lng ) );
  quadrants.add( quadrantCalculation( bounds._northEast.lat, bounds._southWest.lng ) );
  return Array.from( quadrants );
};

export const generateBounds = bounds => {
  if ( !bounds )
    return false;
  const pLat0 = (( bounds._northEast.lat + bounds._southWest.lat ) / 2).toFixed( 6 );
  const pLng0 = (( bounds._northEast.lng + bounds._southWest.lng ) / 2).toFixed( 6 );
  const newBounds = {
    _southWest: {
      lat: pLat0-0.5,
      lng: pLng0-0.5
    },
    _northEast: {
      lat: pLat0+0.5,
      lng: pLng0+0.5
    }
  };
  return newBounds;
};

export const getRoutes = groups => {
  let logsRoute = [];
  let routeJoin = [];
  for ( let [group, videos] of Object.entries( groups ) ) {
    for ( let video of videos ) {
      routeJoin = routeJoin.concat( video.logs.map( point => [point.lat, point.lon, group, new Date( point.time*1000 ).toLocaleString()] ) );
    }
    logsRoute.push( routeJoin );
    routeJoin = [];
  }
  return logsRoute;
};

/* Let's suppose that the videos are ordered by device and videos group
* orderPerDate is optional.
*/
export const structureData = ( videos, orderPerDate = null ) => {
  if ( orderPerDate )
    videos.sort((a,b) => (a.group < b.group) ? 1 : ((b.group < a.group) ? -1 : 0));

  const videosSorted = {};
  for ( let video of videos ){
    let key = video.deviceId+video.group;
    if ( videosSorted[key] )
      videosSorted[key].push( video );
    else
      Object.assign( videosSorted, {[key]: [video]} );
  }
  for ( let [group, videos] of Object.entries( videosSorted ) )
    videos.sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0));

  return videosSorted;
}

export const isDeviceOwn = ( deviceId, devices ) => {
  for ( let device of devices ) {
    if ( device.deviceId === deviceId )
      return true;
  }
  return false;
}

export const uploadProgress = ( videos, devices ) => {
  let videosAvailables = 0;
  if ( isDeviceOwn( videos[0].deviceId, devices ) ) {
    for ( let video of videos ) {
      if ( video.privState === 1 )
        videosAvailables++;
    }
  } else {
    for ( let video of videos ) {
      if ( video.state === 1 )
        videosAvailables++;
    }
  }
  const result = videosAvailables / videos.length * 100;
  return Math.round( result*100 ) / 100;
}
