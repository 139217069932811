import React from "react";

const Contact = ( props ) => {
  let classContent = "simple-main-content";
  if ( props.isAuthenticated )
    classContent = "widthmenu-main-content";
  return (
    <div className={classContent}>
      <div className="fluid-container">
        <div className="page-section-policy">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <br></br>
            <h3 className="lead text-center">  Contact us </h3>
            <hr></hr>
          </div>
          <p>If you need to contact us, please use our <a href="https://www.inargroup.com/contact-us/" className="link-text2" target="_blank" rel="noopener noreferrer">contact form</a>.</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
