import React, { Component, Fragment } from "react";
import { Alert } from "react-bootstrap";
import CookiePopup from "../../components/CookiePopup/CookiePopup";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import config from "../../config";

class Signin extends Component {

  constructor( props ) {
    super( props );

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      errorCognito: ""
    };
  }

  validateForm() {
    return this.state.password.length > 5 && this.state.email.length > 1;
  }

  handleChange = event => {
    this.setState({
      [ event.target.id ]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      await Auth.signIn( this.state.email, this.state.password );
      this.props.userHasAuthenticated( true );
      this.props.history.push( "/" );
    } catch ( e ) {
      this.setState({ errorCognito : e.message });
      this.setState({ isLoading: false });
      config.DEBUG && console.log( "SIGNIN ERROR", this.state.errorCognito );
    }
  }

  render() {
    config.DEBUG && console.log( "SIGNIN RENDER" );
    return (
      <Fragment>
        <div className="welcome-text">
          <h5>Your dashboard cam App and Cloud based time machine </h5>
          <h5>Playback what happend in public roads places around the globe</h5>
          <img src="img/image-responsive.png" alt=""/>
        </div>
        <div className="simple-row">
          <hr></hr>
            <h3>SIGN IN</h3>
            <form name="sign-in" className="contact-form" onSubmit={ this.handleSubmit }>
              <input type="email" name="email" id="email" placeholder="Your Email..." value={ this.state.email } onChange={ this.handleChange }></input>
              <input type="password" name="pass" id="password" placeholder="Your Password..." value={ this.state.password } onChange={ this.handleChange }></input>
              <LoaderButton
                block
                className="button big"
                type="submit"
                disabled={!this.validateForm()}
                isLoading={this.state.isLoading}
                text="Sign in"
              />

              <p><Link to="create-account">Create an account</Link> |  <Link to="reset-password">I forgot my password</Link></p>

              <div className="msgError" style={{marginTop: "15px"}}>
                { this.state.errorCognito ? <Alert bsStyle="danger">{this.state.errorCognito}</Alert> : null }
              </div>
            </form>
            <div className="link-app">
              {/*<a href="#" target="_blank"><img src="img/appstore-apple.png" alt="app store" className="img-responsive"/></a>*/}
              <a href="https://play.google.com/store/apps/details?id=inargroupltd.kronovisor" target="_blank" rel="noopener noreferrer"><img src="img/appstore-google.png" alt="app store" className="img-responsive"/></a>
            </div>
          </div>

          <CookiePopup />

      </Fragment>
    );
  }
}

export default Signin;
