export const toUnixTime = ( year, month, day, hour, minute ) => {
  let start, end;
  if ( year === 'all' ) {
    let yearNow = new Date().getUTCFullYear();
    start = Date.UTC( yearNow )/1000;
    end = Date.UTC( yearNow+1 )/1000;
  } else if ( month === 'all' ) {
    start = new Date( year ).getTime() / 1000;
    end = new Date( parseInt(year)+1+'' ).getTime() / 1000;
  } else if ( day === 'all' ) {
    start = new Date( year, parseInt(month)-1+'' ).getTime() / 1000;
    end = new Date( year, month ).getTime() / 1000;
  } else if ( hour === 'all' ) {
    start = new Date( year, parseInt(month)-1+'', day ).getTime() / 1000;
    end = new Date( year, parseInt(month)-1+'', parseInt(day)+1+'' ).getTime() / 1000;
  } else if ( minute === 'all' ) {
    start = new Date( year, parseInt(month)-1+'', day, hour ).getTime() / 1000;
    end = new Date( year, parseInt(month)-1+'', day, parseInt(hour)+1+'' ).getTime() / 1000;
  } else {
    start = new Date( year, parseInt(month)-1+'', day, hour, minute ).getTime() / 1000;
    end = new Date( year, parseInt(month)-1+'', day, hour, parseInt(minute)+1+'' ).getTime() / 1000;
  }
  return { start, end };
}
