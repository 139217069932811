import React from "react";
import { Button } from "react-bootstrap";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  classNameIcon = "fa fa-spinner",
  disabled = false,
  ...props
}) =>
  <Button
    className={className}
    disabled={disabled || isLoading}
    {...props}
  >
  {isLoading && <i className={classNameIcon + " fa-spin"}></i>}
  {!isLoading ? text || <i className={classNameIcon}></i> : loadingText}
  </Button>;
