import React from 'react'

const CookiePopup = ( props ) => {
  return (
    <div id="cookieConsent">
      <div id="closeCookieConsent"> X </div>
      This website is using cookies <a href="cookie-policy.html" target="_blank"> -More info- </a> <a className="cookieConsentOK">Accept</a>
    </div>
  )
};

export default CookiePopup;
