import React from "react";

const Home = ( props ) => {
  return (
    <div className="widthmenu-main-content">
      <div className="widthmenu-page-section">
        <div className="row">
          <div className="col-md-12">
            <h3 className="lead text-center">
              Your dashboard cam App and Cloud based time machine <br></br>
                Playback what happend in public roads places around the globe
            </h3>
            <br></br>
            <img alt="" src="img/image-responsive.png" width="50%" className=" img-circle center-block"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
