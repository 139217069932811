const dev = {
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://9luo30czz8.execute-api.eu-west-2.amazonaws.com/dev/auth"
  },

  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_Xef4ubCBs",
    APP_CLIENT_ID: "3v8q3n4nceq7bd05a23s99nrmo",
    IDENTITY_POOL_ID: "eu-west-2:4054040a-9eb5-4133-a60b-25bd248b77b4"
  }
};

const prod = {
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://lp27f29an3.execute-api.eu-west-2.amazonaws.com/prod/auth"
  },

  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_La6xbiDde",
    APP_CLIENT_ID: "51be23meqsmmp3f5j3p78bq81u",
    IDENTITY_POOL_ID: "eu-west-2:41183a78-18d5-493c-8be1-c8252b023734"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  DEBUG: process.env.REACT_APP_STAGE !== 'prod',
  ...config
};
