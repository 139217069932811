import React from "react";

const About = ( props ) => {
  let classContent = "simple-main-content";
  if ( props.isAuthenticated )
    classContent = "widthmenu-main-content";
  return (
    <div className={classContent}>
      <div className="fluid-container">
        <div className="page-section-policy">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <br></br>
            <h3 className="lead text-center">  About us </h3>
            <hr></hr>
          </div>

          <p>KronoVisor is a service from <a href="https://www.inargroup.com/about-us/who-we-are/" className="link-text2" target="_blank" rel="noopener noreferrer">Inar Group Ltd</a>.</p>
        </div>
      </div>
    </div>
  );
}

export default About;
