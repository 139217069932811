import React, { Component, Fragment } from 'react';
import Routes from "../../Routes";
import { withRouter, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import FooterAuth from "../../components/FooterAuth/FooterAuth";
import Footer from "../../components/Footer/Footer";
import config from "../../config";

class App extends Component {

  constructor( props ) {
    super( props );
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      responsive: false
    };
  }

  async componentDidMount( ) {
    try {
      if ( await Auth.currentSession( ) ) {
        this.userHasAuthenticated( true );
      }
    } catch( e ) {
      if ( e !== 'No current user' ) {
        config.DEBUG && console.log( e );
      }
    }
    this.setState({ isAuthenticating: false });
  }

  responsiveToggleHandler = () => {
    this.setState( ( prevState ) => {
      return { responsive: !prevState.responsive };
    });
  }

  responsiveCloseHandler = () => {
    this.setState({ responsive: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState( { isAuthenticated: authenticated } );
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated( false );
    // this.props.history.push( "/" );
  }

  render() {
    config.DEBUG && console.log( "APP RENDER" );

    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      !this.state.isAuthenticating &&
      <Fragment>
        <header>
          <div className="profile-image">
            <Link to="/"><img src="img/logoKVblanco2.png" alt=""></img></Link>
          </div>

          <ul className="menu">
            { this.state.isAuthenticated ?
              <li><Link to="signin" onClick={ this.handleLogout }>SIGN OUT</Link></li>
            : <Fragment>
                <li className={this.props.history.location.pathname === "/signin" ? "menu-active" : ""}><Link to="signin">SIGN IN</Link></li>
                <li className={this.props.history.location.pathname === "/create-account" ? "menu-active" : ""}><Link to="create-account">CREATE ACCOUNT</Link></li>
              </Fragment>
            }
            <li><a><i className="fa fa-globe"></i> ENGLISH &nbsp;</a>
              <ul>
                <li><a className="btn"> DEUTSCH </a></li>
                <li><a className="btn"> ESPA&Ntilde;OL </a></li>
                <li><a className="btn"> FRAN&Ccedil;AIS </a> </li>
                <li><a className="btn"> ITALIANO </a></li>
              </ul>
            </li>
          </ul>
        </header>
        { this.state.isAuthenticated ?
          <Fragment>
            <div className="responsive-header visible-xs visible-sm">
              <div className="container">
                <a onClick={() => this.responsiveToggleHandler()} className="toggle-menu">MENU &nbsp; <i className="fa fa-bars"></i></a>
                { this.state.responsive &&  <div className="main-navigation responsive-menu">
                  <ul className="nav">
                    <li onClick={() => this.responsiveCloseHandler()}><Link to="explore-map"><img alt="" className="icon-menu" src="img/explore-map.png"/><p> Explore Map</p></Link></li>
                    <li onClick={() => this.responsiveCloseHandler()}><Link to="uploads"><img alt="" className="icon-menu" src="img/uploads.png"/><p>Uploads</p> </Link>   </li>
                    <li onClick={() => this.responsiveCloseHandler()}><Link to="my-devices"><img alt="" className="icon-menu" src="img/devices.png"/><p>My Devices</p></Link> </li>
                    <li onClick={() => this.responsiveCloseHandler()}><Link to="account"><img alt="" className="icon-menu" src="img/account.png"/><p>Account</p></Link>    </li>
                  </ul>
                </div>}
              </div>
            </div>

            <div className="sidebar-menu hidden-xs hidden-sm">
              <div className="main-navigation">
                <ul className="nav">
                  <li><Link to="explore-map"><img alt="" className="icon-menu" src="img/explore-map.png"/> Explore Map</Link></li>
                  <li><Link to="uploads"><img alt="" className="icon-menu" src="img/uploads.png"/> Uploads </Link></li>
                  <li><Link to="my-devices"><img alt="" className="icon-menu" src="img/devices.png"/> My Devices</Link></li>
                  <li><Link to="account"><img alt="" className="icon-menu" src="img/account.png"/> Account</Link></li>
                </ul>
              </div>
            </div>

            <Routes childProps={childProps} />

            <FooterAuth />
          </Fragment>


          : <Fragment>
              <div className="simple-main-content">
                <div className="fluid-container">
                  <div className="simple-page-section">
                    <Routes childProps={childProps} />
                  </div>
                </div>
              </div>
              <Footer />
            </Fragment>
        }

        <div id="copyright">
          <p className="copyright-text1">Copyright &copy; {new Date().getUTCFullYear()} <a href="http://www.inargroup.com/">Inar Group Ltd </a> </p>
        </div>
      </Fragment>
    );
  }
}

export default withRouter( App );
