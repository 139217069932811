import React from "react";

const Help = ( props ) => {
  return (
    <div className="simple-main-content">
      <div className="fluid-container">
        <div className="simple-page-section">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <br></br>
            <h3 className="lead text-center">  Help </h3>
            <hr></hr>
          </div>
          <div className="WIP">
            <p> Work in progress <i className="fa fa-pencil"></i> </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
