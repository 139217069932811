import React, { Component, Fragment } from "react";
import CookiePopup from "../../components/CookiePopup/CookiePopup";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { Auth, API } from "aws-amplify";
import config from "../../config";

class CreateAccount extends Component {

  constructor( props ) {
    super( props );

    this.state = {
      isLoading: false,
      agreeTerms: false,
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null,
      errorCognito: ""
    };
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  validateForm() {
    return this.state.password.length > 7 && this.state.password === this.state.confirmPassword && this.state.agreeTerms;
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [ event.target.id ]: value
    });
  }

  handleSubmit = async event => {
    event.preventDefault( );
    this.setState({ isLoading: true });
    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password
      });
      this.setState({ newUser });
      this.setState({ errorCognito : "" });
    } catch ( e ) {
      this.setState({ errorCognito : e.message+" or password must be at least 8 characters; must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character" });
    }
    config.DEBUG && console.log( this.state.errorCognito );
    this.setState({ isLoading: false });
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      await Auth.confirmSignUp( this.state.email, this.state.confirmationCode );
      await Auth.signIn( this.state.email, this.state.password );
      this.props.userHasAuthenticated( true );
      this.props.history.push( "/" );

      API.get( "apiGateway", "/account" );

    } catch ( e ) {
      this.setState({ errorCognito : e.message });
    }
    config.DEBUG && console.log( this.state.errorCognito );
    this.setState({ isLoading: false });
  }

  renderForm() {
    return (
      <Fragment>
        <div className="simple-row">
          <hr></hr>
          <h3>CREATE ACCOUNT</h3>
          <form name="create-account" className="contact-form" onSubmit={ this.handleSubmit } onChange={ this.handleChange }>
            <input type="email" name="email" id="email" placeholder="Email address" value={ this.state.email } onChange={ this.handleChange }></input>
            <input type="password" name="password" id="password" placeholder="Password" value={ this.state.password } onChange={ this.handleChange }></input>
            <input type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm Password" value={ this.state.confirmPassword } onChange={ this.handleChange }></input>
            <div>
              <input type="checkbox" name="terms" id="agreeTerms" value="agree-terms" className="check" onChange={ this.handleChange }></input>
              <p className="check-txt">I agree width KronoVisor <Link to="terms-of-use">Terms of use</Link> and <Link to="privacy-policy">Privacy Policy</Link> </p>
            </div>
            <div style={{marginTop: '30px'}}>
              <input type="checkbox" name="notifications" value="agree-notifications" className="check"></input>
              <p className="check-txt">I want to receive email notifications about new features and promotions </p>
            </div>
            <LoaderButton
              block
              className="button big"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Create Account"
            />
            <div className="msgError" style={{marginTop: "15px"}}>
              { this.state.errorCognito ? <Alert bsStyle="danger">{this.state.errorCognito}</Alert> : <Alert bsStyle="info"><li>The password must be at least 8 characters</li>
                <li>must contain at least one lowercase letter</li>
                <li>one uppercase letter</li>
                <li>one numeric digit</li>
                <li>one special character</li>
              </Alert>}
            </div>
          </form>

          <div className="link-app">
            {/*<a href="https://www.apple.com/la/ios/app-store" target="_blank" rel="noopener noreferrer"><img src="img/appstore-apple.png" alt="app store" className="img-responsive"/></a>*/}
            <a href="https://play.google.com/store/apps/details?id=inargroupltd.kronovisor" target="_blank" rel="noopener noreferrer"><img src="img/appstore-google.png" alt="app store" className="img-responsive"/></a>
          </div>
        </div>
      </Fragment>
    );
  }

  renderConfirmationForm() {
    return (
      <Fragment>
        <div className="simple-row">
          <br></br>
          <h3>Account confirmation</h3>

          <form name="verify-code" className="contact-form" onSubmit={ this.handleConfirmationSubmit }>
            <input type="text" name="confirmationCode" id="confirmationCode" placeholder="Verification code" value={ this.state.confirmationCode } onChange={ this.handleChange }></input>
  		      <h5>Please check your email for the code </h5>
            { !this.state.newUser &&
              <Fragment>
                <input type="email" name="email" id="email" placeholder="Email address" value={ this.state.email } onChange={ this.handleChange }></input>
                <input type="password" name="password" id="password" placeholder="Password" value={ this.state.password } onChange={ this.handleChange }></input>
              </Fragment>
            }
            <LoaderButton
              block
              className="button big"
              type="submit"
              disabled={!this.validateConfirmationForm()}
              isLoading={this.state.isLoading}
              text="Verify"
            />
            <div className="msgError" style={{marginTop: "15px"}}>
              { this.state.errorCognito ? <Alert bsStyle="danger">{this.state.errorCognito}</Alert> : null }
            </div>
          </form>
          <br></br>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        { this.state.newUser === null && this.props.history.location.hash !== "#verify"
        ? this.renderForm()
        : this.renderConfirmationForm() }

        <CookiePopup />

      </Fragment>
    );
  }
}

export default CreateAccount;
