export const styleQuadrantCircle = {
  color: 'blue',
  fillOpacity: 1
}

export const styleLog = {
  color: 'blue',
  fillOpacity: 0.8,
  radius: 2
}

export const styleMainMap = {
  height: '500px'
}

export const stylePopup = {
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  zIndex: 1500
}

export const stylePopupMap = {
  height: '40vh'
}
