import React, { Component } from 'react';
import { API } from "aws-amplify";
import ReactPlayer from 'react-player';
import { generateMarkerPosition, binarySearch } from "../../libs/generateMapPosition";
import { structureData, uploadProgress } from "../../libs/mapTools";
import config from "../../config";
import { styleUploadMap } from './stylesMap';
import Map from '../OpenStreetMap/Map';

class Uploads extends Component {

  constructor( props ) {
    super( props );
    this.state = {
      devices: [],
      error: null,
      groups: [],
      isLoaded: false,
      videoSelected: {},
      videoPlaying: -1,
      location: [],
      urlsLoading: false,
      points: [],
      speed: 0,
      baseTime: {},
      zoom: 15
    };
    config.DEBUG && console.log( "CONSTRUCTOR UPLOADS" );
  };

  componentWillUpdate( nextProps, nextState ) {
    config.DEBUG && console.log( "WILLUPDATE UPLOADS nextProps", nextProps );
    config.DEBUG && console.log( "WILLUPDATE UPLOADS nextState", nextState );
  }

  requestUploads = () => {
    API.get( "apiGateway", "/uploads" ).then( uploadsLogsResult => {
      if ( uploadsLogsResult.length > 0 ) {
        const groups = structureData( uploadsLogsResult, true );

        API.get( "apiGateway", "/devices" ).then( devices => {
          this.setState({ isLoaded: true, groups, devices });
        }).catch( error => {
          this.setState({ isLoaded: true, error })
        });
      } else {
        this.setState({ isLoaded: true });
      }
    }).catch( error => {
      this.setState({ isLoaded: true, error })
    });
  }

  componentDidMount() {
    config.DEBUG && console.log( "DIDMOUNT UPLOADS" );
    this.requestUploads();
  }

  componentDidUpdate() {
    config.DEBUG && console.log( "DIDUPDATE UPLOADS" );
  }

  handleVideoPlay = async ( group, videos ) => {
    this.setState({ urlsLoading: true, speed: 0 });
    let urls = [];
    let points = [];
    for ( let video of videos ) {
      urls.push( API.get( "apiGateway", "/videos/"+video.deviceId+"/"+video.start+"/"+video.camera ) );
      points = points.concat( video.logs.map( point => [ point.lon, point.lat, point.time, point.bearing, point.speed ] ) );
    }
    Promise.all( urls ).then( result => {
      this.setState({
        videoSelected: { urls: result, group: group },
        videoPlaying: 0,
        location: [ points[0][1], points[0][0] ],
        points: points,
        baseTime: videos[0].logs[0].time,
        urlsLoading: false
      });
    }).catch( error => {
      this.setState({ error })
    });
  }

  handleOnProgressReactPlayer = progress => {
    const points = this.state.points;
    const currentDate = this.state.baseTime + progress.playedSeconds;

    const index = binarySearch( points, currentDate );
    let location;
    if ( currentDate === points[index][2] )
      location = points[index];
    else if ( currentDate < points[index][2] )
      location = generateMarkerPosition( points[index-1], points[index], currentDate );
    else if ( index < points.length-1 ) // currentDate > points[index] is true
      location = generateMarkerPosition( points[index], points[index+1], currentDate );
    else
      location = points[index];

    config.DEBUG && console.log("location", location);
    this.setState({ location: [ location[1], location[0] ], speed: points[index][4] });
  }

  handleOnEndedReactPlayer = videos => {
    if ( this.state.videoPlaying < this.state.videoSelected.urls.length - 1 ) {
      const videoPlaying = this.state.videoPlaying + 1;
      this.setState({
        videoPlaying,
        baseTime: videos[videoPlaying].start
      });
    }
    else {
      this.setState({ videoPlaying: -1 });
    }
  }

  handleOnDeleteUpload = ( group, videos ) => {
    const confirmed = window.confirm( "Are you sure you want to delete this video?" );
    if ( confirmed ) {
      const removes = [];
      for ( let video of videos )
        removes.push( API.del( "apiGateway", "/uploads/" + video.deviceId+video.start ) );

      Promise.all( removes ).then( result => {
        const groups = {};
        for ( let key of Object.keys( this.state.groups ) )
          if ( key !== group )
            Object.assign( groups, {[key]: this.state.groups[key]} );
        this.setState({ groups });
      }).catch( error => {
        config.DEBUG && console.log( "delete failed", error );
      });
    }
  }

  downloadVideo = async ( device, start, camera ) => {
    API.get( "apiGateway", "/videos/"+device+"/"+start+"/"+camera ).then( url => {
      let link = document.getElementById( 'save' );
      link.setAttribute( 'href', url );
      link.click();
    }).catch( err => {
      alert( 'Error when downloading :(' );
    });
  }

  handleOnSave = videos => {
    for ( let i=0; i<videos.length; i++ )
      this.downloadVideo( videos[i].deviceId, videos[i].start, videos[i].camera );
  }

  unixToString = unix => new Date( unix*1000 ).toLocaleString();

  getDeviceName = deviceId => {
    for ( let device of this.state.devices )
      if ( deviceId === device.deviceId )
        return device.name;
    return "Anonimous device";
  }

  render () {
    config.DEBUG && console.log( "RENDER UPLOADS" );
    const { devices, error, isLoaded, speed, groups, videoSelected, videoPlaying, location, zoom, urlsLoading } = this.state;
    config.DEBUG && console.log( "videoSelected.map", groups);
    if ( error ) {
      config.DEBUG && console.log( "error", error );
      return <div>error...</div>;
    } else {
      return (
        <div className="widthmenu-main-content">
          <div className="widthmenu-page-section">
            <div className="row">
              <div className="col-md-12">
                <h3 className="lead text-center">
                  Uploads
                </h3>
              </div>
            </div>
            {!isLoaded && <div className="container" style={{textAlign: 'center'}}>
                        <i className="fa fa-spinner fa-spin" style={{fontSize: '70px', color: 'red', display: 'inline-block'}}></i>
                      </div>}
            { Object.keys( groups ).map( group =>
              <div key={ group } className="row uploads-holder">
                <div className="col-md-6 col-sm-8 col-xs-12">
                  <div className="uploads-item">
                    {(videoSelected.group === group && videoPlaying !== -1)
                      ? <div className="watermarked">
                          <div className="watermarked-text">
                            {speed}
                          </div>
                          <div className='player-wrapper'>
                            <ReactPlayer
                              playing url={ videoSelected.urls[videoPlaying] }
                              onEnded={ () => this.handleOnEndedReactPlayer( groups[group] ) }
                              onProgress={ progress => this.handleOnProgressReactPlayer( progress ) }
                              onError={ () => { alert( "Video not found" ); this.handleOnEndedReactPlayer( groups[group] ) } }
                              controls
                              className='react-player'
                              width='100%'
                              height='100%'
                            />
                          </div>
                        </div>
                      : <img src="img/image-responsive.png" alt=""></img>
                    }
                    { ( videoSelected.group !== group || ( videoSelected.group === group && videoPlaying === -1 ) ) &&
                      <div className="uploads-hover">
                        <div className="inside">
                          { uploadProgress( groups[group], devices )===100 &&
                            <div style={{textAlign: 'center'}}>
                              <a onClick={ () => this.handleVideoPlay( group, groups[group] ) }><i className="fa fa-play-circle" style={{fontSize: '60px', display: 'inline-block'}}></i></a>
                            </div>
                          }
                          <h5>{this.getDeviceName( groups[group][0].deviceId )}</h5>
                          <p> Start: { this.unixToString( groups[group][0].start ) } / End: { this.unixToString( groups[group][groups[group].length-1].end ) } <br></br>
                            Download status information: { uploadProgress( groups[group], devices ) }% </p>
                          <p className="text-info text-right">
                            <a id="save" target="_blank" rel="noopener noreferrer"></a>
                            { uploadProgress( groups[group], devices )===100 &&
                              <a onClick={ () => this.handleOnSave( groups[group] ) }> <i className="fa fa-2x fa-save"></i></a>
                            }
                            <a onClick={ () => this.handleOnDeleteUpload( group, groups[group] ) }> &nbsp; <i className="fa fa-2x fa-trash-o"></i></a>
                          </p>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="col-md-6 col-sm-4 col-xs-12">
                  <Map
                    id={'upload'+group}
                    center={videoSelected.group === group && !urlsLoading ? location : [ groups[group][0].logs[0].lat, groups[group][0].logs[0].lon ]}
                    className={'uploads-map'}
                    debug={config.DEBUG}
                    location={ videoSelected.group === group && !urlsLoading ? location : [ groups[group][0].logs[0].lat, groups[group][0].logs[0].lon ] }
                    style={styleUploadMap}
                    video={groups[group]}
                    zoom={zoom}
                  >
                  </Map>
                </div>

              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

export default Uploads;
