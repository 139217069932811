import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { API } from "aws-amplify";

import { getLatLngFlagsPopup } from "../../libs/generateMapPosition";
import { toUnixTime } from "../../libs/toUnixTime";

import SimpleTimeline from '../../components/SimpleTimeline/SimpleTimeline';
import ExploreTimeline from '../../components/ExploreTimeline/ExploreTimeline';
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import config from "../../config";

import { getVideos, getClusters } from './callToAPI';
import { styleMainMap, styleCircle, styleLog, styleQuadrantCircle, stylePopup, stylePopupMap } from './stylesMap';
import { getLatLngFromQuadrant, changedQuadrants, getQuadrantsFromBounds, generateBounds,
        getRoutes, structureData } from '../../libs/mapTools';

import Map from '../OpenStreetMap/Map';

class ExploreMap extends Component {

  constructor( props ) {
    super( props );
    this.state = {
      centerPopup: null,
      clusters: null, // is null sii, the clusters must not be shown
      downloadConfirmationLoading: false,
      error: null,
      flagsPopup: null, // latLng of two flags belonging to the popup selection.
      isLoaded: false,
      lastSelection: {
        start: Date.UTC( new Date().getUTCFullYear() )/1000,
        end: Date.UTC( new Date().getUTCFullYear()+1 )/1000,
        available: false
      },
      latLng: [51.505, -0.09],
      mapBounds: {
        _northEast: {
          lat: 81,
          lng: 121,
        },
        _southWest: {
          lat: -27,
          lng: -122
        }
      },
      outputTimeline: null, // start and end of the timeline selection
      pointsPopup: null, // used to calculate flags positions.
      quadrantClusters: null,
      videos: null,
      videoPopup: null,
      zoom: 2
    }
    config.DEBUG && console.log( "CONSTRUCTOR EXPLOREMAP" );
  };

  /* Global variables */
  gClusters = []; // save the data of the clusters for future renderings.
  videoTimeline = []; // input data for SimpleTimeline. Start, end and state of each video.
  selectDate = {
    selectYear: new Date().getUTCFullYear()+"",
    selectMonth: "all",
    selectDay: "all",
    selectHour: "all",
    selectMinute: "all",
    available: false
  };
  /* end global variables */

  onMove = mapBounds => {
    this.setState({ mapBounds });
  };

  onMoveOver = group => {
    return getRoutes( { [group]: this.state.videos[group] } );
  }

  onClickInRoute = route => {
    const video = this.state.videos[route.group];
    if ( !video )
      return;
    let pointsPopup = [];
    this.videoTimeline = [];
    for ( let v of video ) {
      pointsPopup = pointsPopup.concat( v.logs.map( point => [ point.lon, point.lat, point.time, point.bearing, point.speed ] ) );
      this.videoTimeline.push( { start: v.start, end: v.end, state: v.state } );
    }
    this.setState({ videoPopup: video, centerPopup: route.latLng, pointsPopup });
  }

  changedSelection = ( prevSelection, lastSelection ) => {
    return ( !prevSelection && lastSelection ) || prevSelection.start !== lastSelection.start ||
    prevSelection.end !== lastSelection.end || prevSelection.available !== lastSelection.available;
  }

  // function for ExploreTimeline
  handleChange = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.selectDate[ event.target.id ] = value;
    let lastSelection = toUnixTime( this.selectDate.selectYear, this.selectDate.selectMonth, this.selectDate.selectDay, this.selectDate.selectHour, this.selectDate.selectMinute );
    lastSelection.available = this.selectDate.available;
    this.setState({ lastSelection });
  }

  componentWillMount() {
    config.DEBUG && console.log( "WILLMOUNT EXPLOREMAP" );
  }

  componentDidMount() {
    config.DEBUG && console.log( "DIDMOUNT EXPLOREMAP" );
    window.navigator.geolocation.getCurrentPosition( position => {
      this.setState({ latLng: [ position.coords.latitude, position.coords.longitude ] });
    });
    getClusters().then( response => {
      this.gClusters = response.map( point => getLatLngFromQuadrant( point ) );
      this.setState({ clusters: this.gClusters, isLoaded: true });
    }).catch( err => {
      config.DEBUG && console.log( 'error in didmount exploremap', err );
    });
  }

  componentDidUpdate( prevProps, prevState ) {
    config.DEBUG && console.log( "DIDUPDATE EXPLOREMAP" );
    const bounds = this.state.mapBounds;
    if ( bounds ) {
      const diffLat = bounds._northEast.lat - bounds._southWest.lat;
      const diffLng = bounds._northEast.lng - bounds._southWest.lng;
      const showVideos = diffLat*Math.sign( diffLat ) <= 3 || diffLng*Math.sign( diffLng ) <= 3;

      // generate bounds of one degree per one degree
      const oldBounds = generateBounds( prevState.mapBounds );
      const newBounds = generateBounds( bounds );

      if ( ( prevState.clusters && showVideos ) || ( showVideos && changedQuadrants( oldBounds, newBounds ) ) || ( showVideos && this.changedSelection( prevState.lastSelection, this.state.lastSelection ) ) ) {
        const quadrants = getQuadrantsFromBounds( newBounds );
        const promises = [];
        quadrants.forEach( quadrant => {
          promises.push( getVideos( quadrant, this.state.lastSelection ) );
        });

        Promise.all( promises ).then( res => {
          let allVideos = [];
          res.forEach( r => allVideos = allVideos.concat( r ) );
          const videos = structureData( allVideos );
          const routes = getRoutes( videos );
          const quadrantClusters = routes.map( route => [ route[0][0], route[0][1], route[0][2] ] );
          this.setState({ videos, clusters: null, quadrantClusters: quadrantClusters });
        }).catch( err => {
          config.DEBUG && console.log( 'err 2', err );
        });
      }

      if ( !prevState.clusters && !showVideos )
        this.setState({ clusters: this.gClusters, quadrantClusters: null });
      else if ( !showVideos && this.changedSelection( prevState.lastSelection, this.state.lastSelection ) )
        getClusters( this.state.lastSelection ).then( response => {
          this.gClusters = response.map( point => getLatLngFromQuadrant( point ) );
          this.setState({ clusters: this.gClusters, isLoaded: true });
        }).catch( err => {
          config.DEBUG && console.log( 'error in didmount exploremap', err );
        });
    }
  }

  componentWillUnmount() {
    config.DEBUG && console.log( "WILLUNMOUNT EXPLOREMAP" );
  }

  handleOutputTimeline = output => {
    const points = this.state.pointsPopup;
    if ( output.end < points[0][2] || output.start > points[points.length-1][2] ){
      this.setState({ flagsPopup: null, outputTimeline: null });
      return;
    }
    const latLngFlagsPopup = getLatLngFlagsPopup( output, points );
    this.setState({ flagsPopup: latLngFlagsPopup, outputTimeline: output });
  }

  submitHandle = () => {
    this.setState({ downloadConfirmationLoading: true });
    const videosPopup = this.state.videoPopup;
    const outputTimeline = this.state.outputTimeline;
    const request = { videos: [] };
    for ( let video of videosPopup ) {
      if ( video.end >= outputTimeline.start && video.start <= outputTimeline.end )
        request.videos.push( video.start );
    }

    if ( request.videos.length > 0 ) {
      API.post( "apiGateway", "/public-uploads", { body: { deviceId: videosPopup[0].deviceId, videosId: request.videos } })
        .then( response => {
          alert( "Please see the videos available in the Uploads page." );
          this.setState({ downloadConfirmationLoading: false, videoPopup: null });
        }).catch( err => {
          if ( err.response.data.error === "Limit exceeded" )
            alert( "Error: You can't request more than 25 videos" );
          else
            alert( "Error: It is not possible to connect with the server." );
          config.DEBUG && console.log( "error uploads", err.error );
          this.setState({ downloadConfirmationLoading: false, videoPopup: null });
        });
    }
  };

  render() {
    config.DEBUG && console.log( "RENDER EXPLOREMAP" );
    const { centerPopup, clusters, downloadConfirmationLoading, error, flagsPopup,
      latLng, quadrantClusters, videoPopup, zoom } = this.state;
    if ( error ) {
      config.DEBUG && console.log( "error", error );
      return <div>error...</div>;
    } else {
      return (
        <div className="widthmenu-main-content">
          <div className="widthmenu-page-section">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ExploreTimeline size={{width: 500}} handleChange={this.handleChange} />
              <Map
                id='map'
                center={latLng}
                clusters={clusters}
                quadrantClusters={quadrantClusters}
                debug={config.DEBUG}
                geocoder
                onClickInRoute={this.onClickInRoute}
                onMove={this.onMove}
                onMoveOver={this.onMoveOver}
                style={styleMainMap}
                styleCluster={styleCircle}
                styleLog={styleLog}
                styleQuadrantCluster={styleQuadrantCircle}
                zoom={zoom}
              >
              </Map>
            </div>
          </div>
          {/*POPUP VIDEO DOWNLOAD*/}
          {videoPopup &&
            <div style={stylePopup}>
              <Modal.Dialog>
                <Modal.Header>
                  <Modal.Title>Download selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Map
                    id='popup'
                    center={centerPopup}
                    debug={config.DEBUG}
                    flags={flagsPopup}
                    video={videoPopup}
                    style={stylePopupMap}
                    zoom={16}
                  >
                  </Map>
                </Modal.Body>
                <Modal.Footer>
                  <SimpleTimeline data={this.videoTimeline} size={{width: 500}} output={this.handleOutputTimeline} />
                  <Button variant="info" onClick={() => { this.setState({videoPopup:null, flagsPopup:null, submit:false}) } }>Close</Button>
                  <LoaderButton
                    bsStyle="primary"
                    disabled={!flagsPopup}
                    isLoading={downloadConfirmationLoading}
                    text="Download confirmation"
                    onClick={() => this.submitHandle()}>
                  </LoaderButton>
                </Modal.Footer>
              </Modal.Dialog>
            </div>
          }
        </div>
      );
    }
  };
};

export default ExploreMap;
