import React from "react";
import { Route, Switch } from "react-router-dom";

import About from "./components/About/About";
import Account from "./containers/Account/Account";
import Contact from "./components/Contact/Contact";
import CreateAccount from "./containers/CreateAccount/CreateAccount";
import CookiePolicy from "./components/CookiePolicy/CookiePolicy";
import ExploreMap from "./containers/ExploreMap/ExploreMap";
import Help from "./components/Help/Help";
import Home from "./components/Home/Home";
import MyDevices from "./containers/MyDevices/MyDevices";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import Signin from "./containers/Signin/Signin";
import TermsOfUse from "./components/TermsOfUse/TermsOfUse";
import Uploads from "./containers/Uploads/Uploads";

import NotFound from "./components/NotFound/NotFound";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    <AuthenticatedRoute path="/" exact component={Home} props={childProps} />
    <Route path="/about" exact component={() => <About {...childProps} />} />
    <Route path="/contact-us" exact component={() => <Contact {...childProps} />} />
    <Route path="/cookie-policy" exact component={() => <CookiePolicy {...childProps} />} />
    <UnauthenticatedRoute path="/help" exact component={Help} props={childProps} />
    <Route path="/privacy-policy" exact component={() => <PrivacyPolicy {...childProps} />} />
    <Route path="/terms-of-use" exact component={() => <TermsOfUse {...childProps} />} />
    <UnauthenticatedRoute path="/signin" exact component={Signin} props={childProps} />
    <UnauthenticatedRoute path="/create-account" exact component={CreateAccount} props={childProps} />
    <UnauthenticatedRoute path="/reset-password" exact component={ResetPassword} props={childProps} />
    <AuthenticatedRoute path="/account" exact component={Account} props={childProps} />
    <AuthenticatedRoute path="/explore-map" exact component={ExploreMap} props={childProps} />
    <AuthenticatedRoute path="/uploads" exact component={Uploads} props={childProps} />
    <AuthenticatedRoute path="/my-devices" exact component={MyDevices} props={childProps} />
    {
    // <AuthenticatedRoute path="/account" exact component={Account} props={childProps} />
    }

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
