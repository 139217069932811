import React from 'react';

const L = window.L;

class Map extends React.Component {
  componentDidMount() {
    this.props.debug && console.log( "DIDMOUNT MAP" );
    // create map
    const { center, geocoder, id, location, onMove, video, zoom } = this.props;
    this.map = L.map( id, {
      center: center,
      zoom: zoom,
      layers: [
        L.tileLayer( "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }),
      ]
    });

    // add onMove event
    if ( onMove )
      this.map.on( 'moveend', () => onMove( this.map.getBounds() ) );

    // add geocoder
    if ( geocoder )
      L.Control.geocoder().addTo( this.map );

    // add route
    if ( video ) {
      let routeJoin = [];
      for ( let v of video ) {
        routeJoin = routeJoin.concat( v.logs.map( point => [point.lat, point.lon] ) );
      }
      L.polyline( routeJoin, { interactive: false, color: 'blue' } ).addTo( this.map );
    }

    // add position
    if ( location ) {
      this.locationLayer = L.layerGroup();
      this.locationLayer.addLayer( L.marker( location ) );
      this.map.addLayer( this.locationLayer );
    }

  }

  // render the first level of clusters
  renderClusters = points => {
    const markers = L.markerClusterGroup();
    let marker;
    for ( let i=0; i<points.length; i++ ) {
      marker = L.circleMarker( points[i], this.props.styleCluster );
      marker.on( 'click', e => this.map.setView( e.latlng, 9 ) );
      markers.addLayer( marker );
    }
    this.markersCluster = markers;
    this.map.addLayer( markers );
  }

  // render the second level of clusters
  renderQuadrantClusters = ( points, onMoveOver, onClickInRoute ) => {
    const markers = L.markerClusterGroup();
    let marker;
    let group;
    for ( let i=0; i<points.length; i++ ) {
      group = points[i][2];
      marker = L.circleMarker( points[i], { group: group, ...this.props.styleQuadrantCluster } );
      marker.on( 'click', e => onClickInRoute({ group: e.target.options.group, latLng: [e.latlng.lat, e.latlng.lng] }) );
      marker.on( 'mouseover', e => {
        this.renderQuadrantRoute( onMoveOver( e.target.options.group ), onClickInRoute );
      });
      markers.addLayer( marker );
    }
    this.markersQuadrantCluster = markers;
    this.map.addLayer( markers );
  }

  // render the route polyline
  renderQuadrantRoute = ( route, onClickInRoute=null ) => {
    if ( this.routeQuadrantLayer )
      this.map.removeLayer( this.routeQuadrantLayer );
    const routeLayer = L.layerGroup();
    let marker;
    let latLng;
    let group;
    let time;
    let routesHelper = [];
    route = route[0];
    for ( let point of route ) {
      latLng = [ point[0], point[1] ];
      routesHelper.push( latLng );
      group = point[2];
      time = point[3];
      marker = L.circleMarker( latLng, { group: group, time: time, ...this.props.styleLog } );
      if ( onClickInRoute ) {
        marker.on( 'click', e => {
          onClickInRoute({ group: e.target.options.group, latLng: [e.latlng.lat, e.latlng.lng] });
        });
        marker.on( 'mouseover', e => {
          L.popup().setLatLng( [e.latlng.lat, e.latlng.lng] ).setContent( e.target.options.time ).openOn( this.map );
        });
      }
      routeLayer.addLayer( marker );
    }
    const polyline = L.polyline( routesHelper, { interactive: false, color: 'blue' } );
    routeLayer.addLayer( polyline.addTo( this.map ) );
    this.routeQuadrantLayer = routeLayer; // save reference.
    this.map.addLayer( routeLayer );
  }

  // attemp discover when the props are chanded
  changedProps = ( props, prevProps ) => {
    const lengthProps = props.length;
    const lengthPrevProps = prevProps.length;
    if ( lengthProps === 0 && lengthPrevProps === 0 )
      return false;
    return lengthProps !== lengthPrevProps || props[0][0] !== prevProps[0][0] || props[0][1] !== prevProps[0][1];
  }

  componentDidUpdate( prevProps, prevState ) {
    this.props.debug && console.log( "DIDUPDATE MAP" );
    const { clusters, flags, location, onClickInRoute, quadrantClusters } = this.props;

    // add clusters
    if ( clusters && !prevProps.clusters ) {
      if ( this.routeQuadrantLayer )
        this.map.removeLayer( this.routeQuadrantLayer );
      this.renderClusters( clusters );
    } else if ( clusters && prevProps.clusters && this.changedProps( clusters, prevProps.clusters ) ) {
      this.map.removeLayer( this.markersCluster );
      if ( this.routeQuadrantLayer )
        this.map.removeLayer( this.routeQuadrantLayer );
      this.renderClusters( clusters );
    } else if ( prevProps.clusters && !clusters ) {
      this.map.removeLayer( this.markersCluster );
    }

    // add quadrantClusters
    if ( quadrantClusters && !prevProps.quadrantClusters )
      this.renderQuadrantClusters( quadrantClusters, this.props.onMoveOver, onClickInRoute );
    else if ( prevProps.quadrantClusters && !quadrantClusters )
      this.map.removeLayer( this.markersQuadrantCluster );
    else if ( quadrantClusters && prevProps.quadrantClusters && this.changedProps( quadrantClusters, prevProps.quadrantClusters ) ) {
      this.map.removeLayer( this.markersQuadrantCluster );
      this.renderQuadrantClusters( quadrantClusters, this.props.onMoveOver, onClickInRoute );
    }

    if ( this.flagsLayer )
      this.flagsLayer.remove();
    if ( flags ) {
      this.flagsLayer = L.layerGroup();
      this.flagsLayer.addLayer( L.marker( flags[0] ) );
      this.flagsLayer.addLayer( L.marker( flags[1] ) );
      this.map.addLayer( this.flagsLayer );
    }

    if ( this.locationLayer )
      this.locationLayer.remove();
    if ( location ) {
      this.locationLayer = L.layerGroup();
      this.locationLayer.addLayer( L.marker( location ) );
      this.map.addLayer( this.locationLayer );
    }
  }

  render() {
    const { id, style, className } = this.props;
    return <div id={id} style={style} className={className ? className : ''}></div>
  }
}

export default Map;
