import React, { Component } from 'react';
import $ from 'jquery';
import { select, selectAll, event, touches } from 'd3-selection';
import { axisTop, axisLeft } from 'd3-axis';
import { scaleTime, scaleLinear } from 'd3-scale';
import { timeFormat } from 'd3-time-format';
import { brushX } from 'd3-brush';
import { drag } from 'd3-drag';
import { min, max } from 'd3-array';
import config from "../../config";


class ExploreTimeline extends Component {
  constructor( props ){
    super( props );
    this.createExploreTimeline = this.createExploreTimeline.bind( this );
    config.DEBUG && console.log("EXPLORETIMELINE CONSTRUCTOR");
  }

  componentDidMount() {
    this.createExploreTimeline();
    config.DEBUG && console.log( "EXPLORETIMELINE COMPONENTDIDMOUNT" );
  }

  componentDidUpdate() {
    config.DEBUG && console.log( "EXPLORETIMELINE COMPONENTDIDUPDATE");
    // this.createExploreTimeline();
  }

	createExploreTimeline() {

    const node = this.node;
		const size = this.props.size;

    /* Function to convert the timestamp to date */
		function timeConverter(UNIX_timestamp){
			var a = new Date(UNIX_timestamp);
			var year = a.getFullYear();
			var month = a.getMonth() + 1;
			month = month.toString().length == 1 ? "0"+month : month;
			var date = a.getDate();
			date = date.toString().length == 1 ? "0"+date : date;
			var hour = a.getHours();
			hour = hour.toString().length == 1 ? "0"+hour : hour;
			var min = a.getMinutes();
			min = min.toString().length == 1 ? "0"+min : min;
			var sec = a.getSeconds();
			sec = sec.toString().length == 1 ? "0"+sec : sec;

			var time = year + '-' + month + '-' + date + 'T' + hour + ':' + min + ':' + sec ;
			return time;
		}
		/* calculate days of select year */
		function days_of_a_year(year)
		{
			return isLeapYear(year) ? 366 : 365;
		}
		function isLeapYear(year) {
			return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
		}

		/* set datas for chart drawing */
		function setInputData(data){
			var monthDomain = ["january","february","march","april","may","june","july","august","september","october","november","december"];
			if(data.year && data.month && !data.day){
				for ( let key in data.year) {
					inputBdY = key; scaleYear = key;
					$("#selectYear").val(key);
				}

				return [
					{id: "some_id_01", start: scaleYear + '-01-01T00:00', end: scaleYear + '-01-31T23:59', value:data.month.january, available: true},
					{id: "some_id_02", start: scaleYear + '-02-01T00:00', end: scaleYear + '-02-28T23:59', value:data.month.february, available: true},
					{id: "some_id_03", start: scaleYear + '-03-01T00:00', end: scaleYear + '-03-31T23:59', value:data.month.march, available: true},
					{id: "some_id_04", start: scaleYear + '-04-01T00:00', end: scaleYear + '-04-30T23:59', value:data.month.april, available: true},
					{id: "some_id_05", start: scaleYear + '-05-01T00:00', end: scaleYear + '-05-31T23:59', value:data.month.may, available: true},
					{id: "some_id_06", start: scaleYear + '-06-01T00:00', end: scaleYear + '-06-30T23:59', value:data.month.june, available: true},
					{id: "some_id_07", start: scaleYear + '-07-01T00:00', end: scaleYear + '-07-31T23:59', value:data.month.july, available: true},
					{id: "some_id_08", start: scaleYear + '-08-01T00:00', end: scaleYear + '-08-31T23:59', value:data.month.august, available: true},
					{id: "some_id_09", start: scaleYear + '-09-01T00:00', end: scaleYear + '-09-30T23:59', value:data.month.september, available: true},
					{id: "some_id_10", start: scaleYear + '-10-01T00:00', end: scaleYear + '-10-31T23:59', value:data.month.october, available: true},
					{id: "some_id_11", start: scaleYear + '-11-01T00:00', end: scaleYear + '-11-30T23:59', value:data.month.november, available: true},
					{id: "some_id_12", start: scaleYear + '-12-01T00:00', end: scaleYear + '-12-31T23:59', value:data.month.december, available: true}
				];
			}
			if(data.year && data.month && data.day && !data.hour){
				for ( let key in data.month) {
					inputBdY = monthDomain[key-1]; scaleMonth = key;
					$("#selectMonth").val(key);
				}
				for ( let key in data.year) {
					inputBdY += " " + key;
					$("#selectYear").val(key); scaleYear = key;
				}
				scaleMonth = scaleMonth.toString().length == 1 ? "0"+scaleMonth : scaleMonth;
				return [
					{id: "some_id_01", start: scaleYear+'-'+scaleMonth+'-01T00:00', end: scaleYear+'-'+scaleMonth+'-01T23:59', value:data.day["1"], available: true},
					{id: "some_id_02", start: scaleYear+'-'+scaleMonth+'-02T00:00', end: scaleYear+'-'+scaleMonth+'-02T23:59', value:data.day["2"], available: true},
					{id: "some_id_03", start: scaleYear+'-'+scaleMonth+'-03T00:00', end: scaleYear+'-'+scaleMonth+'-03T23:59', value:data.day["3"], available: true},
					{id: "some_id_04", start: scaleYear+'-'+scaleMonth+'-04T00:00', end: scaleYear+'-'+scaleMonth+'-04T23:59', value:data.day["4"], available: true},
					{id: "some_id_05", start: scaleYear+'-'+scaleMonth+'-05T00:00', end: scaleYear+'-'+scaleMonth+'-05T23:59', value:data.day["5"], available: true},
					{id: "some_id_06", start: scaleYear+'-'+scaleMonth+'-06T00:00', end: scaleYear+'-'+scaleMonth+'-06T23:59', value:data.day["6"], available: true},
					{id: "some_id_07", start: scaleYear+'-'+scaleMonth+'-07T00:00', end: scaleYear+'-'+scaleMonth+'-07T23:59', value:data.day["7"], available: true},
					{id: "some_id_08", start: scaleYear+'-'+scaleMonth+'-08T00:00', end: scaleYear+'-'+scaleMonth+'-08T23:59', value:data.day["8"], available: true},
					{id: "some_id_09", start: scaleYear+'-'+scaleMonth+'-09T00:00', end: scaleYear+'-'+scaleMonth+'-09T23:59', value:data.day["9"], available: true},
					{id: "some_id_10", start: scaleYear+'-'+scaleMonth+'-10T00:00', end: scaleYear+'-'+scaleMonth+'-10T23:59', value:data.day["10"], available: true},
					{id: "some_id_11", start: scaleYear+'-'+scaleMonth+'-11T00:00', end: scaleYear+'-'+scaleMonth+'-11T23:59', value:data.day["11"], available: true},
					{id: "some_id_12", start: scaleYear+'-'+scaleMonth+'-31T00:00', end: scaleYear+'-'+scaleMonth+'-31T23:59', value:data.day["31"], available: true}
				];
			}
			if(data.year && data.month && data.day && data.hour && !data.minute){
				for (let key in data.month) {
					inputBdY = monthDomain[key-1]; scaleMonth = key;
					$("#selectMonth").val(key);
				}
				for (let key in data.day) {
					inputBdY += " " + key; scaleDay = key;
					$("#selectDay").val(key);
				}
				for (let key in data.year) {
					inputBdY += ", " + key; scaleYear = key;
					$("#selectYear").val(key);
				}
				scaleMonth = scaleMonth.toString().length == 1 ? "0"+scaleMonth : scaleMonth;
				scaleDay = scaleDay.toString().length == 1 ? "0"+scaleDay : scaleDay;
				return [
					{id: "some_id_01", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T00:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T00:59', value:data.hour["0"], available: true},
					{id: "some_id_02", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T01:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T01:59', value:data.hour["1"], available: true},
					{id: "some_id_03", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T02:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T02:59', value:data.hour["2"], available: true},
					{id: "some_id_04", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T03:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T03:59', value:data.hour["3"], available: true},
					{id: "some_id_05", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T04:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T04:59', value:data.hour["4"], available: true},
					{id: "some_id_06", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T05:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T05:59', value:data.hour["5"], available: true},
					{id: "some_id_07", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T06:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T06:59', value:data.hour["6"], available: true},
					{id: "some_id_08", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T07:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T07:59', value:data.hour["7"], available: true},
					{id: "some_id_09", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T08:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T08:59', value:data.hour["8"], available: true},
					{id: "some_id_10", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T08:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T09:59', value:data.hour["9"], available: true},
					{id: "some_id_11", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T10:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T10:59', value:data.hour["10"], available: true},
					{id: "some_id_12", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T23:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T23:59', value:data.hour["23"], available: true}
				];
			}
			if(data.year && data.month && data.day && data.hour && data.minute){
				for (let key in data.month) {
					inputBdY = monthDomain[key-1]; scaleMonth = key;
					$("#selectMonth").val(key);
				}
				for ( let key in data.day) {
					inputBdY += " " + key; scaleDay = key;
					$("#selectDay").val(key);
				}
				for (let key in data.year) {
					inputBdY += ", " + key; scaleYear = key;
					$("#selectYear").val(key);
				}
				for (let key in data.hour) {
					$("#selectHour").val(key);
					key = key.toString().length == 1 ? "0"+key : key;
					scaleHour = key;
					inputBdY += " " + key + ":00";
				}
				scaleMonth = scaleMonth.toString().length == 1 ? "0"+scaleMonth : scaleMonth;
				scaleDay = scaleDay.toString().length == 1 ? "0"+scaleDay : scaleDay;

				return [
					{id: "some_id_01", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':00:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':00:59', value:data.minute["0"], available: true},
					{id: "some_id_02", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':01:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':01:59', value:data.minute["1"], available: true},
					{id: "some_id_03", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':02:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':02:59', value:data.minute["2"], available: true},
					{id: "some_id_04", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':03:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':03:59', value:data.minute["3"], available: true},
					{id: "some_id_05", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':04:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':04:59', value:data.minute["4"], available: true},
					{id: "some_id_06", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':05:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':05:59', value:data.minute["5"], available: true},
					{id: "some_id_07", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':06:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':06:59', value:data.minute["6"], available: true},
					{id: "some_id_08", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':07:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':07:59', value:data.minute["7"], available: true},
					{id: "some_id_09", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':08:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':08:59', value:data.minute["8"], available: true},
					{id: "some_id_10", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':09:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':09:59', value:data.minute["9"], available: true},
					{id: "some_id_11", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':10:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':10:59', value:data.minute["10"], available: true},
					{id: "some_id_12", start: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':59:00', end: scaleYear+'-'+scaleMonth+'-'+scaleDay+'T'+scaleHour+':59:59', value:data.minute["59"], available: true}
				];
			}
		}

		/* Input Data, start timestamp label */
		var input, inputBdY;
		/* current date, current year, minimum and maximum Date for the visible range */
		var now = new Date();
		var nowYear = now.getFullYear(),
			minXminX = new Date(2018, 0, 0, 23, 59, 59).getTime(),
			maxXmaxX = new Date(now.getFullYear(), 11, 31, 23, 59, 59).getTime();

		/* Set width and height of chart, height of Timeline bars */
		var margin = {top: 30, right: 20, bottom: 30, left: 20},
			width = size.width - margin.left - margin.right,
			height = 120 - margin.top - margin.bottom,
			barHeight = 50;

		var minX, maxX, newMinX, newMaxX, 					// Min and Max Timestamp, new min and max timestamp for X axis domain
			selectScale, selectMinX, selectMaxX, 			// timestamp for timeline selector
			BdY, 											// start timestamp label to bottom of chart
			scaleYear, scaleMonth, scaleDay, scaleHour;		// select value of Year, Month, Day and Hour dropdown list

		/* timestamp of drag start point, dragging point and drag end point, drag scale timestamp : default 1 minute  */
		var dragstartedTime, draggedTime, dragended, dragScale = 60*1000;
		/* tooltip text format of timeline bars */
		var formatTimeTooltip = timeFormat("%b %Y");
		/* tooltip */
		var tooltip = select( node ).append("div")
			.attr("class", "toolTip toolTip-none")

		// draw the x, y axis
		var xScale = scaleTime().range([margin.left, width - margin.right]),
			yScale = scaleLinear().range([height, 0]);

		var	xAxis = axisTop(xScale).tickFormat(timeFormat("%b"));
		let yAxis = axisLeft(yScale);

		/* draw the svg */
		var chart = select( node ).append("svg")
			.attr("preserveAspectRatio", "xMinYMin meet")
			.attr("viewBox", "0 0 " + (width + margin.left + margin.right) + " " + (height + margin.top + margin.bottom))
			.append('g')
			.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

		/* setting border of chart */
		chart.append("rect")
			.attr("x", margin.left)
			.attr("y", 0)
			.attr("height", height)
			.attr("width", width - margin.left - margin.right)
			.style("stroke", "black")
			.style("fill", "none")
			.style("stroke-width", 1);

		/* Add time bars to the chart */
		var span = chart.append("g")
			.attr("transform", "translate(0,0)");

		/* define timeline selector and add event */
		var brush = brushX()
			.extent([[margin.left, 0], [width - margin.right, height]])
			.on("start brush end", brushed);

		var	gBrush, handle;
		/* timeline selector left and right circle button style */
		var brushResizePath = function(d) {
			var e = +(d.type == "e"),
				x = e ? 1 : -1,
				y = height / 2;
			return "M" + (2.5 * x) + "," + y + "A6,6 0 0 " + e + " " + (13.5 * x) + "," + (y + 6) + "V" + (2 * y - 6) + "A6,6 0 0 " + e + " " + (2.5 * x) + "," + (2 * y) + "Z" + "M" + (5.5 * x) + "," + (y + 8) + "V" + (2 * y - 8) + "M" + (8.5 * x) + "," + (y + 8) + "V" + (2 * y - 8);
		}

		/* add drag events in chart */
		select("svg").call(drag()
			.on("start", dragstarted)
			.on("drag", dragged)
			.on("end", dragended));

		/* ajax datas call to draw the initial chart */
		// $.ajax({
		// 	url: "api1.php?",
		// 	type: "GET",
		// 	data:{ "year" : nowYear, "month" : 'all', "day" : 'all', "hour" : 'all', "minute" : 'all', "available" : 'true' },
		// 	success: function(result){
		// 		var inputData = JSON.parse(result);
		// 		input = setInputData(inputData);
    //
		// 		drawChart(input);
		// 		showInputData(JSON.parse(result));
		// 	}
		// });
    let curret_year = new Date().getUTCFullYear()+"";
    input = setInputData( {"year":{[curret_year]:30},"month":{"january":5,"february":15,"march":12,"april":5,"may":5,"june":0,"july":0,"august":0,"september":0,"october":0,"november":0,"december":0}} );
		drawChart(input);
    showInputData(input);

		/* function to draw the initial chart */
		function drawChart(input){
			input.forEach(function(d){
				d.start = new Date(d.start).getTime();
				d.end = new Date(d.end).getTime();
			});

			minX = min(input, function(d) { return d.start});
			maxX = max(input, function(d) { return d.end});

			newMinX = minX;
      newMaxX = maxX;

			// draw the axis
			xScale.domain([minX, maxX]);
			yScale.domain([0, Math.log(max(input, function(d) { return d.value; }))]);

			// Add x axis
			chart.append("g")
				.attr("class", "axis axis-x");
			chart.select('.axis-x')
				.attr("transform", "translate(0,0)")
				.call(xAxis);

			// Add the Y Axis
			chart.append("g")
				.attr("class", "axis axis-y");
			chart.select('.axis-y')
				.attr("transform", "translate(" + margin.left + ",0)")
				.call(yAxis);

			BdY = chart.append("text")
				.attr("x", margin.left)
				.attr("y", height + margin.top - 10)
				.text( inputBdY )

			// Add spans
			span.selectAll('rect')
				.data(input)
			  .enter().append('rect')
				.attr("class", 'chart-span')
				.attr('x', function(d, i) {
					if(d.start > newMaxX){
						return  xScale(newMaxX);
					}else{
						if(d.start < newMinX){
							return  xScale(newMinX);
						}else{
							return  xScale(d.start);
						}
					}
				})
				.attr('y', function(d) {
					if(d.value == 0){
						var y = height;
					}else if(d.value == 1) {
						var y = yScale(Math.log(d.value+0.5));
					}else{
						var y = yScale(Math.log(d.value));
					}
					return y;
				 })
				.attr('width', function(d, i) {
					if(d.start > newMaxX){
						return 0;
					}else{
						if(d.start < newMinX){
							if(d.end > newMinX){
								return d.end < newMaxX ? xScale(d.end) - xScale(newMinX) : xScale(newMaxX) - xScale(newMinX);
							}else{
								return 0;
							}
						}else{
							return d.end > newMaxX ? xScale(newMaxX) - xScale(d.start) : xScale(d.end) - xScale(d.start);
						}
					}
				})
				.attr('height', function(d) {
					if(d.value == 0){
						var y = height;
					}else if(d.value == 1) {
						var y = yScale(Math.log(d.value+0.5));
					}else{
						var y = yScale(Math.log(d.value));
					}
					return height - y;
				})
				.style("fill", function(d){ return d.available == true ? "#e91e63" : "#ffb199" })
				.on("mousemove", function(d){
					if(!touches(this)){
						tooltip
							.style("left", event.layerX - 30 + "px")
							.style("top", event.layerY - 45 + "px")
							.attr("class", "toolTip")
							.html(formatTimeTooltip(d.start) + "<br>" + (d.value) + " items");
					}
				})
				.on("mouseout", function(d){
					tooltip.attr("class", "toolTip toolTip-none")
				})
				// .on("touchmove", function(d){
				// 	event.preventDefault();
      			// 	event.stopPropagation();
				// 	tooltip
				// 		.style("left", touches(this)[0][0] + "px")
				// 		.style("top", touches(this)[0][1] + 20 + "px")
				// 		.attr("class", "toolTip")
				// 		.html(formatTimeTooltip(d.start) + "<br>" + (d.value) + " items");
				// })
				// .on("touchend", function(d){
				// 	tooltip.attr("class", "toolTip toolTip-none m-fadeOut")
				// })
				.on("click", function(d){
					var sDate = new Date(d.start),
						sYear = sDate.getFullYear(),
						sMonth = sDate.getMonth() + 1,
						sDay = sDate.getDate(),
						sHour = sDate.getHours(),
						sMin = sDate.getMinutes(),
						sSec = sDate.getSeconds();

					var eDate = new Date(d.end),
						eMonth = eDate.getMonth() + 1,
						eDay = eDate.getDate(),
						eHour = eDate.getHours(),
						eMin = eDate.getMinutes(),
						eSec = eDate.getSeconds();

					if(sMonth == eMonth && sDay != eDay){
						//var dataParam = {"year":sYear, "month":sMonth, "day":'all', "hour":'all', "minute":'all', "available":'true'};
						var dataParam = {"year":sYear, "month":sMonth, "day":'all', "hour":'all', "minute":'all', "available":'true'};

						xAxis.tickFormat(timeFormat("%d"));
						formatTimeTooltip = timeFormat("%b %d, %Y");
					}
					if(sMonth == eMonth && sDay == eDay && sHour != eHour){
						//var dataParam = {"year":sYear, "month":sMonth, "day":sDay, "hour":'all', "minute":'all', "available":'true'};
						var dataParam = {"year":sYear, "month":sMonth, "day":sDay, "hour":'all', "minute":'all', "available":'true'};

						xAxis.tickFormat(timeFormat("%H:00"));
						formatTimeTooltip = timeFormat("%b %d, %Y %H:00");
					}
					if(sMonth == eMonth && sDay == eDay && sHour == eHour && sMin != eMin){
						//var dataParam = {"year":sYear, "month":sMonth, "day":sDay, "hour":sHour, "minute":'all', "available":'true'};
						var dataParam = {"year":sYear, "month":sMonth, "day":sDay, "hour":sHour, "minute":'all', "available":'true'};

						xAxis.tickFormat(timeFormat("%M:00"));
						formatTimeTooltip = timeFormat("%b %d, %Y %H:%M");
					}
					if(sMonth == eMonth && sDay == eDay && sHour == eHour && sMin == eMin){
						$("#selectMinute").val(sMin);
					}

					if(dataParam){
						$.ajax({
							url: "api1.php?",
							type: "GET",
							data: dataParam,
							success: function(result){
								var inputData = JSON.parse(result);

								input = setInputData(inputData);
								input.forEach(function(d){
									d.start = new Date(d.start).getTime();
									d.end = new Date(d.end).getTime();
								})
								newMinX = min(input, function(d) { return d.start});
								newMaxX = max(input, function(d) { return d.end});
								BdY.text(inputBdY.charAt(0).toUpperCase() + inputBdY.slice(1));

								selectAll(".chart-span").remove();
								updateChart(newMinX, newMaxX, input);
								showInputData(JSON.parse(result));
							}
						});
					}
				});

		}

		/* chart timeline period selector event */
		function brushed() {
			//if (event.sourceEvent && event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom
			var s = event.selection || xScale.range();

			if (s == null) {
				handle.attr("display", "none");
			} else {
				handle.attr("display", null).attr("transform", function(d, i) { return "translate(" + [ s[i], - height / 4] + ")"; });

				let selectTimeRange = s.map(xScale.invert, xScale);
				selectMinX = selectTimeRange[0];
				selectMaxX = selectTimeRange[1];

				var outMinX = timeConverter(selectMinX);
				var outMaxX = timeConverter(selectMaxX);
				var output = { start: outMinX, end: outMaxX };

				$("#output_data").text(JSON.stringify(output));
			}
		}

		/* chart drag start event */
		function dragstarted(d) {
			dragstartedTime = new Date(xScale.invert(event.x)).getTime();
		}

		/* chart dragging event */
		function dragged(d) {
			draggedTime = new Date(xScale.invert(event.x)).getTime();
			dragScale = (newMaxX - newMinX)/60;

			if((draggedTime - dragstartedTime) > 0){
				newMinX = newMinX - dragScale;
				newMaxX = newMaxX - dragScale;

				dragChart(newMinX, newMaxX);
				if(gBrush) {
					gBrush.call(brush.move, [selectMinX, selectMaxX].map(xScale));
				}
			}else{
				newMinX = newMinX + dragScale;
				newMaxX = newMaxX + dragScale;

				dragChart(newMinX, newMaxX);
				if(gBrush) {
					gBrush.call(brush.move, [selectMinX, selectMaxX].map(xScale));
				}
			}
		}

		/* chart drag end event */
		function dragended(d) {
			let dragendedTime = new Date(xScale.invert(event.x)).getTime();

			if((draggedTime - dragstartedTime) > 0){
				leftRedraw();
			}else{
				rightRedraw();
			}
		}

		/* timeline period select event */
		$("#selectYear").on("change", function(){
			selectChart();
		})
		$("#selectMonth").on("change", function(){
			if($("#selectMonth").val() == 2){
				var febDays = days_of_a_year($("#selectYear")) == 365 ? 28 : 29;
				$("#selectDay option").remove();
				var options = '<option value="all">DAY</option>';
				for(var i = 0; i < febDays; i++ ){
					options += '<option value="'+(i+1)+'">'+(i+1)+'</option>';
				}
				$("#selectDay").append(options);
			}else{
				var days = [31,28,31,30,31,30,31,31,30,31,30,31];
				$("#selectDay option").remove();
				var options = '<option value="all">DAY</option>';
				for(var i = 0; i < days[$("#selectMonth").val()-1]; i++ ){
					options += '<option value="'+(i+1)+'">'+(i+1)+'</option>';
				}
				$("#selectDay").append(options);
			}

			selectChart();
		})
		$("#selectDay").on("change", function(){
			selectChart();
		})
		$("#selectHour").on("change", function(){
			selectChart();
		})

		/* function to redraw the chart when timeline period is selected */
		function selectChart(){
			var	sYear = $("#selectYear").val(),
				sMonth = $("#selectMonth").val(),
				sDay = $("#selectDay").val(),
				sHour = $("#selectHour").val(),
				sMin = $("#selectMinute").val();

			if(sYear != "all" && sMonth == "all" && sDay == "all" && sHour == "all"){
				var dataParam = {"year":sYear, "month":'all', "day":'all', "hour":'all', "minute":'all', "available":'true'};

				xAxis.tickFormat(timeFormat("%b"));
				formatTimeTooltip = timeFormat("%b %Y");
			}
			if(sYear != "all" && sMonth != "all" && sDay == "all" && sHour == "all"){
				var dataParam = {"year":sYear, "month":sMonth, "day":'all', "hour":'all', "minute":'all', "available":'true'};

				xAxis.tickFormat(timeFormat("%d"));
				formatTimeTooltip = timeFormat("%b %d, %Y");
			}
			if(sYear != "all" && sMonth != "all" && sDay != "all" && sHour == "all"){
				var dataParam = {"year":sYear, "month":sMonth, "day":sDay, "hour":'all', "minute":'all', "available":'true'};

				xAxis.tickFormat(timeFormat("%H:00"));
				formatTimeTooltip = timeFormat("%b %d, %Y %H:00");
			}
			if(sYear != "all" && sMonth != "all" && sDay != "all" && sHour != "all"){
				var dataParam = {"year":sYear, "month":sMonth, "day":sDay, "hour":sHour-1, "minute":'all', "available":'true'};

				xAxis.tickFormat(timeFormat("%M:00"));
				formatTimeTooltip = timeFormat("%b %d, %Y %H:%M");
			}

			// if(dataParam){
			// 	$.ajax({
			// 		url: "api1.php?",
			// 		type: "GET",
			// 		data: dataParam,
			// 		success: function(result){
			// 			var inputData = JSON.parse(result);
      //
			// 			input = setInputData(inputData);
			// 			input.forEach(function(d){
			// 				d.start = new Date(d.start).getTime();
			// 				d.end = new Date(d.end).getTime();
			// 			})
			// 			newMinX = min(input, function(d) { return d.start});
			// 			newMaxX = max(input, function(d) { return d.end});
			// 			BdY.text(inputBdY.charAt(0).toUpperCase() + inputBdY.slice(1));
      //
			// 			selectAll(".chart-span").remove();
			// 			updateChart(newMinX, newMaxX, input);
			// 			showInputData(JSON.parse(result));
			// 		}
			// 	});
			// }

		}

		/* left button click event */
		$("#shiftLeft1").on("click", function(){
			leftRedraw();
		})

		/* right button click event */
		$("#shiftRight1").on("click", function(){
			rightRedraw();
		})

		/* add or remove the timeline selector in the chart */
		$("#fineSelect").on("click", function(){
			if($("#fineSelect").prop("checked") == true){
				selectScale = (newMaxX - newMinX) / 24;
				selectMinX = (newMaxX + newMinX) / 2 - selectScale;
				selectMaxX = (newMaxX + newMinX) / 2 + selectScale;

				$("#selectYear").attr("disabled","disabled");
				$("#selectMonth").attr("disabled","disabled");
				$("#selectDay").attr("disabled","disabled");
				$("#selectHour").attr("disabled","disabled");
				$("#selectMinute").attr("disabled","disabled");

				gBrush = chart.append("g")
					.attr("class", "brush")
					.call(brush)

				handle = gBrush.selectAll(".handle--custom")
					.data([{type: "w"}, {type: "e"}])
					.enter().append("path")
					.attr("class", "handle--custom")
					.attr("stroke", "#7030A0")
					.style("stroke-width", 2)
					.attr("cursor", "ew-resize")
					.attr("d", brushResizePath)

				gBrush.call(brush.move, [selectMinX, selectMaxX].map(xScale));

				// removes crosshair cursor
				selectAll('.brush>.overlay').remove();
			}else{
				$("#selectYear").removeAttr("disabled");
				$("#selectMonth").removeAttr("disabled");
				$("#selectDay").removeAttr("disabled");
				$("#selectHour").removeAttr("disabled");
				$("#selectMinute").removeAttr("disabled");

				gBrush.remove();
			}
		})

		/* reset button click event */
		$("#reset").on("click", function(){
			$("#selectYear").val(nowYear);
			$("#selectMonth").val("all");
			$("#selectDay").val("all");
			$("#selectHour").val("all");
			$("#selectMinute").val("all");

			$.ajax({
				url: "api1.php?",
				type: "GET",
				data:{ "year" : nowYear, "month" : 'all', "day" : 'all', "hour" : 'all', "minute" : 'all', "available" : 'true' },
				success: function(result){
					var inputData = JSON.parse(result);

					input = setInputData(inputData);
					input.forEach(function(d){
						d.start = new Date(d.start).getTime();
						d.end = new Date(d.end).getTime();
					})
					newMinX = min(input, function(d) { return d.start});
					newMaxX = max(input, function(d) { return d.end});

					xAxis.tickFormat(timeFormat("%b"));
					formatTimeTooltip = timeFormat("%b %Y");
					BdY.text(inputBdY.charAt(0).toUpperCase() + inputBdY.slice(1));

					selectAll(".chart-span").remove();
					updateChart(newMinX, newMaxX, input);
					showInputData(JSON.parse(result));
				}
			});

		})

		/* function to redraw the chart when timeline period is changed */
		function updateChart(sMinX, eMaxX, data){
			xScale.domain([sMinX, eMaxX]);
			yScale.domain([0, Math.log(max(input, function(d) { return d.value; }))]);
			chart.select(".axis-x").call(xAxis);
			chart.select(".axis-y").call(yAxis);

			span.selectAll('rect')
				.data(data)
				.enter().append('rect')
				.attr("class", 'chart-span')
				.attr('x', function(d, i) {
					if(d.start > eMaxX){
						return  xScale(eMaxX);
					}else{
						if(d.start < sMinX){
							return  xScale(sMinX);
						}else{
							return  xScale(d.start);
						}
					}
				})
				.attr('y', function(d) {
					if(d.value == 0){
						var y = height;
					}else if(d.value == 1) {
						var y = yScale(Math.log(d.value+0.5));
					}else{
						var y = yScale(Math.log(d.value));
					}
					return y;
				 })
				.attr('width', function(d, i) {
					if(d.start > eMaxX){
						return 0;
					}else{
						if(d.start < sMinX){
							if(d.end > sMinX){
								return d.end < eMaxX ? xScale(d.end) - xScale(sMinX) : xScale(eMaxX) - xScale(sMinX);
							}else{
								return 0;
							}
						}else{
							return d.end > eMaxX ? xScale(eMaxX) - xScale(d.start) : xScale(d.end) - xScale(d.start);
						}
					}
				})
				.attr('height', function(d) {
					if(d.value == 0){
						var y = height;
					}else if(d.value == 1) {
						var y = yScale(Math.log(d.value+0.5));
					}else{
						var y = yScale(Math.log(d.value));
					}
					return height - y;
				})
				.style("fill", function(d){ return d.available == true ? "#e91e63" : "#ffb199" })
				.on("mousemove", function(d){
					if(!touches(this)){
						tooltip
							.style("left", event.layerX - 30 + "px")
							.style("top", event.layerY - 45 + "px")
							.attr("class", "toolTip")
							.html(formatTimeTooltip(d.start) + "<br>" + (d.value) + " items");
					}
				})
				.on("mouseout", function(d){
					tooltip.attr("class", "toolTip toolTip-none")
				})
				// .on("touchmove", function(d){
				// 	event.preventDefault();
      			// 	event.stopPropagation();
				// 	tooltip
				// 		.style("left", touches(this)[0][0] + "px")
				// 		.style("top", touches(this)[0][1] + 20 + "px")
				// 		.attr("class", "toolTip")
				// 		.html(formatTimeTooltip(d.start) + "<br>" + (d.value) + " items");
				// })
				// .on("touchend", function(d){ console.log("touchend");
				// 	tooltip.attr("class", "toolTip toolTip-none m-fadeOut")
				// })
				.on("click", function(d){
					var sDate = new Date(d.start),
						sYear = sDate.getFullYear(),
						sMonth = sDate.getMonth() + 1,
						sDay = sDate.getDate(),
						sHour = sDate.getHours(),
						sMin = sDate.getMinutes(),
						sSec = sDate.getSeconds();

					var eDate = new Date(d.end),
						eMonth = eDate.getMonth() + 1,
						eDay = eDate.getDate(),
						eHour = eDate.getHours(),
						eMin = eDate.getMinutes(),
						eSec = eDate.getSeconds();

					if(sMonth == eMonth && sDay != eDay){
						//var dataParam = {"year":sYear, "month":sMonth, "day":'all', "hour":'all', "minute":'all', "available":'true'};
						var dataParam = {"year":sYear, "month":sMonth, "day":'all', "hour":'all', "minute":'all', "available":'true'};

						xAxis.tickFormat(timeFormat("%d"));
						formatTimeTooltip = timeFormat("%b %d, %Y");
					}
					if(sMonth == eMonth && sDay == eDay && sHour != eHour){
						//var dataParam = {"year":sYear, "month":sMonth, "day":sDay, "hour":'all', "minute":'all', "available":'true'};
						var dataParam = {"year":sYear, "month":sMonth, "day":sDay, "hour":'all', "minute":'all', "available":'true'};

						xAxis.tickFormat(timeFormat("%H:00"));
						formatTimeTooltip = timeFormat("%b %d, %Y %H:00");
					}
					if(sMonth == eMonth && sDay == eDay && sHour == eHour && sMin != eMin){
						//var dataParam = {"year":sYear, "month":sMonth, "day":sDay, "hour":sHour, "minute":'all', "available":'true'};
						var dataParam = {"year":sYear, "month":sMonth, "day":sDay, "hour":sHour, "minute":'all', "available":'true'};

						xAxis.tickFormat(timeFormat("%M:00"));
						formatTimeTooltip = timeFormat("%b %d, %Y %H:%M");
					}
					if(sMonth == eMonth && sDay == eDay && sHour == eHour && sMin == eMin){
						$("#selectMinute").val(sMin);
					}

					if(dataParam){
						$.ajax({
							url: "api1.php?",
							type: "GET",
							data: dataParam,
							success: function(result){
								var inputData = JSON.parse(result);

								input = setInputData(inputData);
								input.forEach(function(d){
									d.start = new Date(d.start).getTime();
									d.end = new Date(d.end).getTime();
								})
								newMinX = min(input, function(d) { return d.start});
								newMaxX = max(input, function(d) { return d.end});
								BdY.text(inputBdY.charAt(0).toUpperCase() + inputBdY.slice(1));

								selectAll(".chart-span").remove();
								updateChart(newMinX, newMaxX, input);
								showInputData(JSON.parse(result));
							}
						});
					}
				});
		}

		/* function to drag the chart when drag event */
		function dragChart(sMinX, eMaxX, data){
			xScale.domain([sMinX, eMaxX]);
			yScale.domain([0, Math.log(max(input, function(d) { return d.value; }))]);
			chart.select(".axis-x").call(xAxis);
			chart.select(".axis-y").call(yAxis);

			selectAll(".chart-span")
				.attr('x', function(d, i) {
					if(d.start > eMaxX){
						return  xScale(eMaxX);
					}else{
						if(d.start < sMinX){
							return  xScale(sMinX);
						}else{
							return  xScale(d.start);
						}
					}
				})
				.attr('y', function(d) {
					if(d.value === 0){
						var y = height;
					}else if(d.value === 1) {
						var y = yScale(Math.log(d.value+0.5));
					}else{
						var y = yScale(Math.log(d.value));
					}
					return y;
				 })
				.attr('width', function(d, i) {
					if(d.start > eMaxX){
						return 0;
					}else{
						if(d.start < sMinX){
							if(d.end > sMinX){
								return d.end < eMaxX ? xScale(d.end) - xScale(sMinX) : xScale(eMaxX) - xScale(sMinX);
							}else{
								return 0;
							}
						}else{
							return d.end > eMaxX ? xScale(eMaxX) - xScale(d.start) : xScale(d.end) - xScale(d.start);
						}
					}
				})
				.attr('height', function(d) {
					if(d.value == 0){
						var y = height;
					}else if(d.value == 1) {
						var y = yScale(Math.log(d.value+0.5));
					}else{
						var y = yScale(Math.log(d.value));
					}
					return height - y;
				})
				.style("fill", function(d){ return d.available == true ? "#e91e63" : "#ffb199" })
		}

		/* function to redraw the chart when left click or left drag event */
		function leftRedraw(){
			var	sYear = $("#selectYear").val(),
				sMonth = $("#selectMonth").val(),
				sDay = $("#selectDay").val(),
				sHour = $("#selectHour").val(),
				sMin = $("#selectMinute").val();

			if((sYear != "all" && sYear-1 >= 2018) && sMonth == "all" && sDay == "all" && sHour == "all"){
				var dataParam = {"year":sYear-1, "month":'all', "day":'all', "hour":'all', "minute":'all', "available":'true'};

				xAxis.tickFormat(timeFormat("%b"));
				formatTimeTooltip = timeFormat("%b %Y");
			}
			if(sYear != "all" && sMonth != "all" && sDay == "all" && sHour == "all"){
				if(sMonth == 1){
					if(sYear-1 >= 2018){
						var dataParam = {"year":sYear-1, "month":12, "day":'all', "hour":'all', "minute":'all', "available":'true'};
					}
				}else{
					var dataParam = {"year":sYear, "month":sMonth-1, "day":'all', "hour":'all', "minute":'all', "available":'true'};
				}

				xAxis.tickFormat(timeFormat("%d"));
				formatTimeTooltip = timeFormat("%b %d, %Y");
			}
			if(sYear != "all" && sMonth != "all" && sDay != "all" && sHour == "all"){
				if(sDay == 1){
					if(sMonth == 1){
						if(sYear-1 >= 2018){
							var dataParam = {"year":sYear-1, "month":12, "day":31, "hour":'all', "minute":'all', "available":'true'};
						}
					}else{
						var lastDay = new Date(sYear, sMonth-1, 0);
						var dataParam = {"year":sYear, "month":sMonth-1, "day":lastDay.getDate(), "hour":'all', "minute":'all', "available":'true'};
					}
				}else{
					var dataParam = {"year":sYear, "month":sMonth, "day":sDay-1, "hour":'all', "minute":'all', "available":'true'};
				}

				xAxis.tickFormat(timeFormat("%H:00"));
				formatTimeTooltip = timeFormat("%b %d, %Y %H:00");
			}
			if(sYear != "all" && sMonth != "all" && sDay != "all" && sHour != "all"){
				if(sHour == 0){
					if(sDay == 1){
						if(sMonth == 1){
							if(sYear-1 >= 2018){
								var dataParam = {"year":sYear-1, "month":12, "day":31, "hour":23, "minute":'all', "available":'true'};
							}
						}else{
							var lastDay = new Date(sYear, sMonth-1, 0);
							var dataParam = {"year":sYear, "month":sMonth-1, "day":lastDay.getDate(), "hour":23, "minute":'all', "available":'true'};
						}
					}else{
						var dataParam = {"year":sYear, "month":sMonth, "day":sDay-1, "hour":23, "minute":'all', "available":'true'};
					}
				}else{
					var dataParam = {"year":sYear, "month":sMonth, "day":sDay, "hour":sHour-1, "minute":'all', "available":'true'};
				}
				xAxis.tickFormat(timeFormat("%M:00"));
				formatTimeTooltip = timeFormat("%b %d, %Y %H:%M");
			}

			if(dataParam){
				$.ajax({
					url: "api1.php?",
					type: "GET",
					data: dataParam,
					success: function(result){
						var inputData = JSON.parse(result);

						input = setInputData(inputData);
						input.forEach(function(d){
							d.start = new Date(d.start).getTime();
							d.end = new Date(d.end).getTime();
						})
						newMinX = min(input, function(d) { return d.start});
						newMaxX = max(input, function(d) { return d.end});
						BdY.text(inputBdY.charAt(0).toUpperCase() + inputBdY.slice(1));

						selectAll(".chart-span").remove();
						updateChart(newMinX, newMaxX, input);
						showInputData(JSON.parse(result));
					}
				});
			}
		}

		/* function to redraw the chart when right click or right drag event */
		function rightRedraw(){
			var	sYear = $("#selectYear").val(),
				sMonth = $("#selectMonth").val(),
				sDay = $("#selectDay").val(),
				sHour = $("#selectHour").val(),
				sMin = $("#selectMinute").val();

			if((sYear != "all" && parseInt(sYear)+1 <= 2019) && sMonth == "all" && sDay == "all" && sHour == "all"){
				var dataParam = {"year":parseInt(sYear)+1, "month":'all', "day":'all', "hour":'all', "minute":'all', "available":'true'};

				xAxis.tickFormat(timeFormat("%b"));
				formatTimeTooltip = timeFormat("%b %Y");
			}
			if(sYear != "all" && sMonth != "all" && sDay == "all" && sHour == "all"){
				if(sMonth == 12){
					if(parseInt(sYear)+1 <= 2019){
						var dataParam = {"year":parseInt(sYear)+1, "month":1, "day":'all', "hour":'all', "minute":'all', "available":'true'};
					}
				}else{
					var dataParam = {"year":sYear, "month":parseInt(sMonth)+1, "day":'all', "hour":'all', "minute":'all', "available":'true'};
				}

				xAxis.tickFormat(timeFormat("%d"));
				formatTimeTooltip = timeFormat("%b %d, %Y");
			}
			if(sYear != "all" && sMonth != "all" && sDay != "all" && sHour == "all"){
				var lastDay = new Date(sYear, sMonth, 0);
				if(sDay == lastDay.getDate()){
					if(sMonth == 12){
						if(parseInt(sYear)+1 <= 2019){
							var dataParam = {"year":parseInt(sYear)+1, "month":1, "day":1, "hour":'all', "minute":'all', "available":'true'};
						}
					}else{
						var dataParam = {"year":sYear, "month":parseInt(sMonth)+1, "day":1, "hour":'all', "minute":'all', "available":'true'};
					}
				}else{
					var dataParam = {"year":sYear, "month":sMonth, "day":parseInt(sDay)+1, "hour":'all', "minute":'all', "available":'true'};
				}

				xAxis.tickFormat(timeFormat("%H:00"));
				formatTimeTooltip = timeFormat("%b %d, %Y %H:00");
			}
			if(sYear != "all" && sMonth != "all" && sDay != "all" && sHour != "all"){
				if(sHour == 23){
					var lastDay = new Date(sYear, sMonth, 0);
					if(sDay == lastDay.getDate()){
						if(sMonth == 12){
							if(parseInt(sYear)+1 <= 2019){
								var dataParam = {"year":parseInt(sYear)+1, "month":1, "day":1, "hour":0, "minute":'all', "available":'true'};
							}
						}else{
							var dataParam = {"year":sYear, "month":parseInt(sMonth)+1, "day":1, "hour":0, "minute":'all', "available":'true'};
						}
					}else{
						var dataParam = {"year":sYear, "month":sMonth, "day":parseInt(sDay)+1, "hour":0, "minute":'all', "available":'true'};
					}
				}else{
					var dataParam = {"year":sYear, "month":sMonth, "day":sDay, "hour":parseInt(sHour)+1, "minute":'all', "available":'true'};
				}
				xAxis.tickFormat(timeFormat("%M:00"));
				formatTimeTooltip = timeFormat("%b %d, %Y %H:%M");
			}

			// if(dataParam){
			// 	$.ajax({
			// 		url: "api1.php?",
			// 		type: "GET",
			// 		data: dataParam,
			// 		success: function(result){
			// 			var inputData = JSON.parse(result);
      //
			// 			input = setInputData(inputData);
			// 			input.forEach(function(d){
			// 				d.start = new Date(d.start).getTime();
			// 				d.end = new Date(d.end).getTime();
			// 			})
			// 			newMinX = min(input, function(d) { return d.start});
			// 			newMaxX = max(input, function(d) { return d.end});
			// 			BdY.text(inputBdY.charAt(0).toUpperCase() + inputBdY.slice(1));
      //
			// 			selectAll(".chart-span").remove();
			// 			updateChart(newMinX, newMaxX, input);
			// 			showInputData(JSON.parse(result));
			// 		}
			// 	});
			// }
		}

		/* function to show input data */
		function showInputData(data){
			$("#input_data").text(JSON.stringify(data));
		}

	}

	render() {
    const years = () => {
      let acum = [];
      for ( let year=new Date().getUTCFullYear(); year>=2019; year-- )
        acum.push( <option value={year}>{year}</option> );
      return acum;
    }
    return (
        <div className="container-fluid">
      		<div className="row mt-3">
      			<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      				<div className="input-group justify-content-center mb-3">
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <select className="form-control" id="selectYear" onChange={this.props.handleChange}>
        						<option value="all">YEAR</option>
                    { years().map( item => item ) }
        					</select>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
        					<select className="form-control" id="selectMonth" onChange={this.props.handleChange}>
        						<option value="all">MONTH</option>
        						<option value="1">1</option>
        						<option value="2">2</option>
        						<option value="3">3</option>
        						<option value="4">4</option>
        						<option value="5">5</option>
        						<option value="6">6</option>
        						<option value="7">7</option>
        						<option value="8">8</option>
        						<option value="9">9</option>
        						<option value="10">10</option>
        						<option value="11">11</option>
        						<option value="12">12</option>
        					</select>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <select className="form-control" id="selectDay" onChange={this.props.handleChange}>
        						<option value="all">DAY</option>
        						<option value="1">1</option>
        						<option value="2">2</option>
        						<option value="3">3</option>
        						<option value="4">4</option>
        						<option value="5">5</option>
        						<option value="6">6</option>
        						<option value="7">7</option>
        						<option value="8">8</option>
        						<option value="9">9</option>
        						<option value="10">10</option>
        						<option value="11">11</option>
        						<option value="12">12</option>
        						<option value="13">13</option>
        						<option value="14">14</option>
        						<option value="15">15</option>
        						<option value="16">16</option>
        						<option value="17">17</option>
        						<option value="18">18</option>
        						<option value="19">19</option>
        						<option value="20">20</option>
        						<option value="21">21</option>
        						<option value="22">22</option>
        						<option value="23">23</option>
        						<option value="24">24</option>
        						<option value="25">25</option>
        						<option value="26">26</option>
        						<option value="27">27</option>
        						<option value="28">28</option>
        						<option value="29">29</option>
        						<option value="30">30</option>
        						<option value="31">31</option>
        					</select>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <select className="form-control" id="selectHour" onChange={this.props.handleChange}>
        						<option value="all">HOUR</option>
        						<option value="0">0</option>
        						<option value="1">1</option>
        						<option value="2">2</option>
        						<option value="3">3</option>
        						<option value="4">4</option>
        						<option value="5">5</option>
        						<option value="6">6</option>
        						<option value="7">7</option>
        						<option value="8">8</option>
        						<option value="9">9</option>
        						<option value="10">10</option>
        						<option value="11">11</option>
        						<option value="12">12</option>
        						<option value="13">13</option>
        						<option value="14">14</option>
        						<option value="15">15</option>
        						<option value="16">16</option>
        						<option value="17">17</option>
        						<option value="18">18</option>
        						<option value="19">19</option>
        						<option value="20">20</option>
        						<option value="21">21</option>
        						<option value="22">22</option>
        						<option value="23">23</option>
        					</select>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                  <select className="form-control" id="selectMinute" onChange={this.props.handleChange}>
        						<option value="all">MINUTE</option>
        						<option>0</option>
        						<option>1</option>
        						<option>2</option>
        						<option>3</option>
        						<option>4</option>
        						<option>5</option>
        						<option>6</option>
        						<option>7</option>
        						<option>8</option>
        						<option>9</option>
        						<option>10</option>
        						<option>11</option>
        						<option>12</option>
        						<option>13</option>
        						<option>14</option>
        						<option>15</option>
        						<option>16</option>
        						<option>17</option>
        						<option>18</option>
        						<option>19</option>
        						<option>20</option>
        						<option>21</option>
        						<option>22</option>
        						<option>23</option>
        						<option>24</option>
        						<option>25</option>
        						<option>26</option>
        						<option>27</option>
        						<option>28</option>
        						<option>29</option>
        						<option>30</option>
        						<option>31</option>
        						<option>32</option>
        						<option>33</option>
        						<option>34</option>
        						<option>35</option>
        						<option>36</option>
        						<option>37</option>
        						<option>38</option>
        						<option>39</option>
        						<option>40</option>
        						<option>41</option>
        						<option>42</option>
        						<option>43</option>
        						<option>44</option>
        						<option>45</option>
        						<option>46</option>
        						<option>47</option>
        						<option>48</option>
        						<option>49</option>
        						<option>50</option>
        						<option>51</option>
        						<option>52</option>
        						<option>53</option>
        						<option>54</option>
        						<option>55</option>
        						<option>56</option>
        						<option>57</option>
        						<option>58</option>
        						<option>59</option>
        					</select>
                </div>
      					<div className="custom-control custom-switch mt-2 ml-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
      						<input type="checkbox" className="custom-control-input" id="available" name="example" onChange={this.props.handleChange} />
      						<label className="custom-control-label" htmlFor="available">Available</label>
      					</div>
      					{/* <div className="custom-control custom-switch mt-2 ml-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
      						<input type="checkbox" className="custom-control-input" id="fineSelect" name="example" />
      						<label className="custom-control-label" for="fineSelect">Fine selection</label>
      					</div> */}
      					{/*<a type="button" id="reset" className="btn btn-light ml-2">
      						Reset
      					</a>*/}
      				</div>
      				{/*<a type="button" id="shiftLeft1" className="btn btn-light p-1">
      					<img src="img/chevron-left.svg"></img>
      				</a>*/}

              {/* <svg ref={node => this.node = node}
                width={'100%'} height={'100%'}
              /> */}

              {/* <a type="button" id="shiftRight1" className="btn btn-light p-1">
      					<img src="img/chevron-right.svg"></img>
      				</a> */}
      			</div>
      		</div>
      	</div>
  	);
  }
}
export default ExploreTimeline;
