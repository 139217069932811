import React, { Component } from 'react';
import { API } from "aws-amplify";
import { Modal, Button } from "react-bootstrap";
import QRCode from 'qrcode.react';

import Map from '../OpenStreetMap/Map';
import { getLatLngFlagsPopup } from "../../libs/generateMapPosition";
import { styleMainMap, styleLog, styleQuadrantCircle, stylePopupMap, stylePopup } from './stylesMap';
import { getRoutes, structureData } from '../../libs/mapTools';
import config from "../../config";
import ExploreTimeline from '../../components/ExploreTimeline/ExploreTimeline';
import SimpleTimeline from '../../components/SimpleTimeline/SimpleTimeline';
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { toUnixTime } from "../../libs/toUnixTime";

class MyDevices extends Component {

  constructor( props ) {
    super( props );
    this.state = {
      centerPopup: null,
      error: null,
      devices: [],
      deviceEditingName: null,
      deviceEditingId: null,
      downloadConfirmationLoading: false,
      flagsPopup: null,
      isLoaded: false,
      isLoading: false,
      latLng: [51.505, -0.09],
      lastSelection: {
        start: Date.UTC( new Date().getUTCFullYear() )/1000,
        end: Date.UTC( new Date().getUTCFullYear()+1 )/1000,
        available: false
      },
      linkedDevice: false,
      outputTimeline: null, // start and end of the timeline selection
      pointsPopup: [],
      videos: null,
      videoPopup: null,
      qrCode: null,
      time: 30,
      zoom: 15,
      zoomPopup: 14
    }
    config.DEBUG && console.log( "CONSTRUCTOR MYDEVICES" );
  };

  /* Global variables */
  videoTimeline = []; // input data for SimpleTimeline. Start, end and state of each video.
  selectDate = {
    selectYear: new Date().getUTCFullYear()+"",
    selectMonth: "all",
    selectDay: "all",
    selectHour: "all",
    selectMinute: "all",
    available: false
  };
  requestDevice = "";
  /* end global variables */

  handleChange = event => {
    this.setState({ [ event.target.id ]: event.target.value });
  }

  // function for ExploreTimeline
  handleChangeTimeline = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.selectDate[ event.target.id ] = value;
    let lastSelection = toUnixTime( this.selectDate.selectYear, this.selectDate.selectMonth, this.selectDate.selectDay, this.selectDate.selectHour, this.selectDate.selectMinute );
    lastSelection.available = this.selectDate.available;
    this.setState({ lastSelection });
  }

  requestDevices = () => {
    API.get( "apiGateway", "/devices" ).then( devices => {
      this.setState({ isLoaded: true, isLoading: false, devices })
    }).catch( error => {
      this.setState({ isLoaded: true, isLoading: false, error })
    });
  }

  componentDidMount() {
    config.DEBUG && console.log( "DIDMOUNT MYDEVICES" );
    window.navigator.geolocation.getCurrentPosition( position => {
      this.setState({ latLng: [ position.coords.latitude, position.coords.longitude ] });
    });
    this.requestDevices();
  }

  changedSelection = ( prevSelection, lastSelection ) => {
    return ( !prevSelection && lastSelection ) || prevSelection.start !== lastSelection.start ||
    prevSelection.end !== lastSelection.end || prevSelection.available !== lastSelection.available;
  }

  componentDidUpdate( prevProps, prevState ) {
    config.DEBUG && console.log( "DIDUPDATE MYDEVICES" );
    if ( this.state.qrCode && this.state.time > 0 ) {
      setTimeout(() => API.get( "apiGateway", "/devices" )
        .then( devices => {
          if ( devices.length > this.state.devices.length ) {
            this.setState({ linkedDevice: true, devices });
            setTimeout(() => this.setState({ linkedDevice: false, qrCode: null, time: 30 }) , 2000 );
          } else {
            this.setState({ time: this.state.time - 2 });
          }
        }).catch( error => {
          config.DEBUG && console.log( error );
        })
      , 2000 );
    } else if ( this.state.qrCode && this.state.time <= 0 ) {
      this.setState({ qrCode: null, time: 30 });
    }

    if ( this.requestDevice && this.changedSelection( prevState.lastSelection, this.state.lastSelection ) )
      this.getLogsByDevice( this.requestDevice, this.state.lastSelection );
  }

  createQr = () => {
    API.post( "apiGateway", "/devices").then( res => {
      config.DEBUG && console.log( res );
      this.setState({ qrCode: res.code });
    }).catch( err => config.DEBUG && console.log( err ) );
  }

  deleteDevice = deviceId => {
    API.del( "apiGateway", "/devices/" + deviceId ).then( res => {
      config.DEBUG && console.log( res );
      this.requestDevices();
    }).catch( err => config.DEBUG && console.log( err ) );
  }

  mouseLeaveHandler = point => {
    this.setState({ logPopup: point });
  };

  handleOutputTimeline = output => {
    const points = this.state.pointsPopup;
    if ( output.end < points[0][2] || output.start > points[points.length-1][2] ){
      this.setState({ flagsPopup: null, outputTimeline: null });
      return;
    }
    const latLngFlagsPopup = getLatLngFlagsPopup( output, points );
    this.setState({ flagsPopup: latLngFlagsPopup, outputTimeline: output });
  }

  submitHandle = () => {
    this.setState({ downloadConfirmationLoading: true });
    const videosPopup = this.state.videoPopup;
    const outputTimeline = this.state.outputTimeline;
    const request = { videos: [] };
    for ( let video of videosPopup ) {
      if ( video.end >= outputTimeline.start && video.start <= outputTimeline.end )
        request.videos.push( video.start );
    }

    if ( request.videos.length > 0 ) {
      const params = { deviceId: videosPopup[0].deviceId, videosId: request.videos };
      API.post( "apiGateway", "/uploads", { body: params }).then( response => {
        alert( "Please see the videos available in the Uploads page." );
        this.setState({ downloadConfirmationLoading: false, videoPopup: null });
      }).catch( err => {
        if ( err.response.data.error === "Limit exceeded" )
          alert( "Error: You can't request more than 25 videos" );
        else
          alert( "Error: It is not possible to connect with the server." );
        config.DEBUG && console.log( "error uploads", err.error );
        this.setState({ downloadConfirmationLoading: false, videoPopup: null });
      });
    }
  };

  getLogsByDevice = ( deviceId, selection = null ) => {
    this.requestDevice = deviceId;
    API.get( "apiGateway", "/public/logs/device/" + deviceId, { queryStringParameters: selection } ).then( response => {
      const groups = structureData( response );
      const routes = getRoutes( groups );
      const quadrantClusters = routes.map( route => [ route[0][0], route[0][1], route[0][2] ] );
      this.setState({ videos: groups, quadrantClusters });
    }).catch( error => {
      this.setState({ error });
    });
  }

  onMoveOver = group => {
    return getRoutes( { [group]: this.state.videos[group] } );
  }

  onClickInRoute = route => {
    const video = this.state.videos[route.group];
    if ( !video )
      return;
    let pointsPopup = [];
    this.videoTimeline = [];
    for ( let v of video ) {
      pointsPopup = pointsPopup.concat( v.logs.map( point => [ point.lon, point.lat, point.time, point.bearing, point.speed ] ) );
      this.videoTimeline.push( { start: v.start, end: v.end, state: v.privState } );
    }
    this.setState({ videoPopup: video, centerPopup: route.latLng, pointsPopup });
  }

  submitDeviceName = device => {
    if ( !this.state.deviceEditingId ) {
      this.setState({ deviceEditingId: device.deviceId, deviceEditingName: device.name })
    } else if ( this.state.deviceEditingId !== device.deviceId  ) {
      this.setState({ deviceEditingId: device.deviceId, deviceEditingName: device.name });
    } else {
      API.put( "apiGateway", "/devices/" + device.deviceId, { queryStringParameters: { name: this.state.deviceEditingName } } ).then( res => {
        config.DEBUG && console.log( "DEVICES", res );
        this.requestDevices();
        this.setState({ deviceEditingId: null, deviceEditingName: null })
      }).catch( err => {
        config.DEBUG && console.log( "DEVICES", err );
        this.setState({ deviceEditingId: null, deviceEditingName: null })
      });
    }
  }

  render () {
    config.DEBUG && console.log( "RENDER MYDEVICES" );
    const { centerPopup, error, isLoaded, deviceEditingName, devices, deviceEditingId, lastSelection,
      downloadConfirmationLoading, latLng, flagsPopup, quadrantClusters, qrCode, videoPopup, zoom } = this.state;
      return (
        <div className="widthmenu-main-content">
          <div className="widthmenu-page-section">
            <div className="row">
              <div className="col-md-12">
                <h3 className="lead text-center">
                  My Devices
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
              { qrCode && <Modal.Dialog>
                <Modal.Header closeButton onHide={() => this.setState({qrCode: null})}><p>Link code</p></Modal.Header>
                <Modal.Body>
                  <p align="center">Please Scan now this code in the Kronovisor App under </p>
                  <p align="center">Settings -> Account -> Link </p>
                  <br></br>
                  <div align="center"><QRCode size={128} value={qrCode} /></div>
                </Modal.Body>
                <Modal.Footer>
                  { this.state.linkedDevice
                    ? <p align="center" style={{'color': 'green'}}>Connected</p>
                    : <p align="center">Waiting connection</p>
                  }
                </Modal.Footer>

              </Modal.Dialog> }
              <button onClick={this.createQr} type="button" name="add-device" className="btn btn-danger" disabled={!isLoaded}> + add device</button>
             	<ul className="my-devices">
                <li><a type="text">My Devices</a></li>

                { error ? <a type="text">Network error</a> : !isLoaded ? <i className="fa fa-spinner fa-spin" style={{fontSize: '30px', color: 'red'}}></i>
                  :
                  devices.map( device => {
                    return <li key={device.deviceId}>
                        { deviceEditingId && deviceEditingId === device.deviceId ?
                          <input type="text" value={deviceEditingName} id="deviceEditingName" onChange={this.handleChange}></input>
                          : <a href="#" onClick={() => this.getLogsByDevice( device.deviceId, lastSelection )} type="text">{device.name}</a>
                        }
                        <a href="#" onClick={() => { if ( window.confirm( 'Are you sure you wish to delete this item?' ) ) this.deleteDevice( device.deviceId )} } className="icon-device"><i className="fa fa-trash-o"></i></a>
                        <a href="#" className="icon-device" onClick={() => this.submitDeviceName( device )}><i className="fa fa-edit"></i></a>
                      </li>
                  })
                }
              </ul>
            </div>
            { isLoaded &&
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <ExploreTimeline size={{width: 500}} handleChange={this.handleChangeTimeline} />
              <Map
                id='myDevicesMap'
                center={latLng}
                quadrantClusters={quadrantClusters}
                debug={config.DEBUG}
                geocoder
                onClickInRoute={this.onClickInRoute}
                onMoveOver={this.onMoveOver}
                style={styleMainMap}
                styleLog={styleLog}
                styleQuadrantCluster={styleQuadrantCircle}
                zoom={zoom}
              >
              </Map>
              {/*POPUP VIDEO DOWNLOAD*/}
              {videoPopup &&
                <div style={stylePopup}>
                  <Modal.Dialog>
                    <Modal.Header>
                      <Modal.Title>Download selection</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Map
                        id='myDevicesPopup'
                        center={centerPopup}
                        debug={config.DEBUG}
                        flags={flagsPopup}
                        video={videoPopup}
                        style={stylePopupMap}
                        zoom={16}
                      >
                      </Map>
                    </Modal.Body>
                    <Modal.Footer>
                      <SimpleTimeline data={this.videoTimeline} size={{width: 500}} output={this.handleOutputTimeline} />
                      <Button variant="info" onClick={() => { this.setState({ videoPopup:null, flagsPopup:null, submit:false }) } }>Close</Button>
                      <LoaderButton
                        bsStyle="primary"
                        disabled={!flagsPopup}
                        isLoading={downloadConfirmationLoading}
                        text="Download confirmation"
                        onClick={() => this.submitHandle()}>
                      </LoaderButton>
                    </Modal.Footer>
                  </Modal.Dialog>
                </div>
              }
            </div>
          }
          </div>
        </div>
      );
    }
  }

export default MyDevices;
