import React from "react";

const CookiePolicy = ( props ) => {
  let classContent = "simple-main-content";
  if ( props.isAuthenticated )
    classContent = "widthmenu-main-content";
  return (
  <div className={classContent}>
    <div className="fluid-container">
      <div className="page-section-policy">
        <div className="col-lg-12">
        <br></br>
           <h3 className="lead text-center">  Cookies Policy </h3>
           <hr></hr>
           <h5 className="lead">What are cookies?</h5>
           <p> Cookies are small text files that may be placed on your computer or mobile device when you visit one of our websites. We, and some third-party service providers, use cookies on our websites. Some are persistent cookies (cookies that remain on your hard drive for an extended period of time) and some are session ID cookies (cookies that expire when you close your browser). </p>
           <p> We also use other tracking technologies like web beacons (sometimes called “tracking beacons” or “clear gifs”) and local storage. These are tiny graphics files that contain a unique identifier that enable us to recognise when someone has visited our websites or opened an email that we have sent them.</p>
           <p> You can find more information about cookies at <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a> and <a href="www.youronlinechoices.com/">www.youronlinechoices.com</a>. </p>
           <h5 className="lead"> How we use cookies </h5>
        <p>We use cookies  to collect information, including personal data. To find out more about personal data and how we respect your data see our Privacy Policy. This document sets out more about the types of cookies we use, and why. </p>
            <p> Cookies help us to operate our websites and services, enhance and customise your experience across our websites and services, perform analytics and deliver advertising and marketing that is relevant to you.</p>
            <p> Third party cookies enable third party features or functionality to be provided on or through our websites and services, such as advertising, interactive content and analytics. </p>
        <h5 className="lead"> What cookies do we use?</h5>
            <p> In the tables below you can see a description of each cookie that we use, what it does and when its expires.</p>
        <table className="table-responsive table-bordered table-hover table-condensed">
            <tr><th>Name</th><th>Purpose</th><th>Expires</th></tr>
            <tr>
            <td>Google AdSense</td>
            <td>AdSense uses cookies to improve advertising and avoiding showing ads that the user has already seen.</td>
            <td>2 years</td>
            </tr>
            </table>

        <p>We also use Google Analytics to monitor how our website is used. Google Analytics collects information anonymously and generates reports detailing information such as the number of visits to the website, where visitors generally came from, how long they stayed on the website and which pages they visited. Google Analytics places several persistent cookies on your computer’s hard drive.  These do not collect any personal data. If you do not agree to this you can disable persistent cookies in your browser. This will prevent Google Analytics from logging your visits.  </p>
            <p> We also use social media buttons and/or plugins on this website that allow you to connect with your social network in various ways. For these to work the relevant social media sites (Twitter, Facebook and LinkedIn) will set cookies through our website which may be used to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies.</p>

           <h5 className="lead"> How can you control cookies?</h5>
           <p> You can accept or reject cookies by amending your web browser controls. But if you choose not to allow the use of cookies, your experience of our websites will be limited and many integral aspects  might not work at all.</p>
           <p>To change your cookie settings, or if you want to be notified each time a cookie is about to be used, you should amend the settings provided in your web browser to prevent us from storing cookies on your computer hard drive. </p>
           <p>Most advertising networks also offer you the option to opt out of targeted advertising. For more info, visit <a href="http://www.aboutads.info/choices/">www.aboutads.info/choices</a> or <a href="http://www.youronlinechoices.com">www.youronlinechoices.com</a></p>
           <p>You can manage your cookie settings by following your browser's instructions. Here are some links that might be of assistance:</p>

        <ul>
          <li>	· <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">
                    Google Chrome </a> </li>
          <li>	· <a href="https://support.microsoft.com/en-nz/help/17442/windows-internet-explorer-delete-manage-cookies">
                  Microsoft Internet Explorer </a></li>
          <li>	· <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
                  Mozilla Firefox </a> </li>
          <li>	· <a href="https://support.apple.com/en-nz/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                  Safari </a></li>
        </ul>

           <p>If you have any questions on this Cookie Policy you can contact us at
           <a href="mailto:info@kronovisor.com">info@kronovisor.com</a></p>


        </div>
      </div>
    </div>
  </div>
  );
}

export default CookiePolicy;
