import React, { Component, Fragment } from "react";
import CookiePopup from "../../components/CookiePopup/CookiePopup";
import { Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { Auth } from "aws-amplify";

class ResetPassword extends Component {

  constructor( props ) {
    super( props );

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      requiredCode: false,
      errorCognito: ""
    };
  }

  validateEmail() {
    return this.state.email.length > 1;
  }

  validateConfirmationForm() {
    return this.state.email.length > 1 && this.state.confirmationCode.length > 0 && this.state.password.length > 5 && this.state.password === this.state.confirmPassword;
  }

  handleChange = event => {
    this.setState({
      [ event.target.id ]: event.target.value
    });
  }

  handleRecoverSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      await Auth.forgotPassword( this.state.email );
      this.setState({ requiredCode: true });
      this.setState({ errorCognito : "" });
    } catch ( e ) {
      this.setState({ errorCognito : e.message });
    }
    this.setState({ isLoading: false });
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      await Auth.forgotPasswordSubmit( this.state.email, this.state.confirmationCode, this.state.confirmPassword );
      await Auth.signIn( this.state.email, this.state.password );
      this.props.userHasAuthenticated( true );
      this.props.history.push( "/" );
    } catch ( e ) {
      this.setState({ errorCognito : e.message });
      this.setState({ isLoading: false });
    }
  }

  renderGetCodeForm() {
    return (
      <Fragment>
        <div className="simple-row">
          <br></br>
          <h3>Reset Password</h3>
          <form name="reset-pass" className="contact-form" onSubmit={ this.handleRecoverSubmit }>
            <input type="email" name="email" id="email" placeholder="Email adress" value={ this.state.email } onChange={ this.handleChange }></input>
            <LoaderButton
              block
              className="button big"
              type="submit"
              disabled={!this.validateEmail()}
              isLoading={this.state.isLoading}
              text="Get a new code"
            />
            <p className="text-right"><a onClick={ () => this.props.history.push( "reset-password#verify" ) }><i className="fa fa-2x fa-arrow-circle-right"></i></a></p>
            <div className="msgError">
              { this.state.errorCognito ? <Alert bsStyle="danger">{this.state.errorCognito}</Alert> : null }
            </div>
          </form>
          <br></br>
        </div>
      </Fragment>
    );
  }

  renderConfirmationForm() {
    return (
      <Fragment>
        <div className="simple-row">
          <br></br>
          <h3>Reset Password</h3>
          <form name="verify-code" className="contact-form" onSubmit={ this.handleConfirmationSubmit }>
            { !this.state.requiredCode &&
              <input type="email" name="email" id="email" placeholder="Your Email" value={ this.state.email } onChange={ this.handleChange }></input>
            }
            <input type="text" name="confirmationCode" id="confirmationCode" placeholder="Verification code" value={ this.state.confirmationCode } onChange={ this.handleChange }></input>
  				  <h5>Please check your email for the code </h5>
            <input type="password" name="password" id="password" placeholder="New password" value={ this.state.password } onChange={ this.handleChange }></input>
            <input type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm password" value={ this.state.confirmPassword } onChange={ this.handleChange }></input>
            <LoaderButton
              block
              className="button big"
              type="submit"
              disabled={!this.validateConfirmationForm()}
              isLoading={this.state.isLoading}
              text="Confirm password"
            />
            <div className="msgError">
              { this.state.errorCognito ? <Alert bsStyle="danger">{this.state.errorCognito}</Alert> : null }
            </div>
          </form>
          <br></br>
        </div>
      </Fragment>
    );
  }


  render() {
    return (
      <Fragment>

        { this.state.requiredCode === false && this.props.history.location.hash !== "#verify"
        ? this.renderGetCodeForm()
        : this.renderConfirmationForm() }

        <CookiePopup />

      </Fragment>
    );
  }
}

export default ResetPassword;
