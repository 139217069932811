function nextPoint( lat1, lon1, dist, brng ) {
  dist = dist / 6371.0;
  brng = brng * Math.PI / 180;
  lat1 = lat1 * Math.PI / 180;
  lon1 = lon1 * Math.PI / 180;

  const lat2 = Math.asin( Math.sin( lat1 ) * Math.cos( dist ) + Math.cos( lat1 ) * Math.sin( dist ) * Math.cos( brng ) );
  const a = Math.atan2( Math.sin( brng ) * Math.sin( dist ) * Math.cos( lat1 ), Math.cos( dist ) - Math.sin( lat1 ) * Math.sin( lat2 ) );
  let lon2 = lon1 + a;

  lon2 = ( lon2 + 3 * Math.PI ) % ( 2 * Math.PI ) - Math.PI;

  return [ lon2 * 180 / Math.PI, lat2 * 180 / Math.PI ];
};

function distance( lat1, lat2, lon1, lon2 ) {
  const R = 6371; // Radius of the earth

  const latDistance = ( lat2 - lat1 ) * Math.PI / 180;
  const lonDistance = ( lon2 - lon1 ) * Math.PI / 180;
  const a = Math.sin( latDistance / 2 ) * Math.sin( latDistance / 2 )
          + Math.cos( lat1 * Math.PI / 180 ) * Math.cos( lat2 * Math.PI / 180 )
          * Math.sin( lonDistance / 2 ) * Math.sin( lonDistance / 2 );
  const c = 2 * Math.atan2( Math.sqrt( a ), Math.sqrt( 1 - a ) );
  const distance = R * c * 1000; // convert to meters

  return distance;
};

export function generateMarkerPosition( geo1, geo2, currentDate ) {
  const lon1 = geo1[0];
  const lon2 = geo2[0];
  const lat1 = geo1[1];
  const lat2 = geo2[1];
  let distance1 = distance( lat1, lat2, lon1, lon2 ) / 1000.0;

  const bearing = geo1[3];
  // Calculate traveled distance in current date
  const positionsSecondsDiff = ( geo2[2] - geo1[2] );
  const roadTimeFromPoint = ( currentDate - geo1[2] );

  if ( positionsSecondsDiff )
    distance1 = Math.abs( roadTimeFromPoint * distance1 / positionsSecondsDiff );
  else
    distance1 = 0;

  return nextPoint( lat1, lon1, distance1, bearing );
};

export function binarySearch( array, value ) {
  let guess;
  let min = 0;
  let max = array.length - 1;
  let item;

  while ( min <= max ) {
    guess = Math.floor( ( min + max ) / 2 );
    item = array[guess][2];
    if ( item === value )
      return guess;
    else if ( item < value )
      min = guess + 1;
    else
      max = guess - 1;
  }
  return guess;
};

export const getLatLngFlagsPopup = ( output, points ) => {
  let minPosition;
  let maxPosition;
  if ( output.start > points[0][2] ) {
    const indexMin = binarySearch( points, output.start );
    if ( output.start === points[indexMin][2] )
      minPosition = points[indexMin];
    else if ( output.start < points[indexMin][2] )
      minPosition = generateMarkerPosition( points[indexMin-1], points[indexMin], output.start );
    else if ( indexMin < points.length-1 ) // output.start.getTime() > points[indexMin].getTime() is true
      minPosition = generateMarkerPosition( points[indexMin], points[indexMin+1], output.start );
    else
      minPosition = points[indexMin];
  } else {
    minPosition = [points[0][0], points[0][1]];
  }

  if ( output.end < points[points.length-1][2] ) {
    const indexMax = binarySearch( points, output.end );
    if ( output.end === points[indexMax][2] )
      maxPosition = points[indexMax];
    else if ( output.end < points[indexMax][2] )
      maxPosition = generateMarkerPosition( points[indexMax-1], points[indexMax], output.end );
    else if ( indexMax < points.length-1 ) // output.end.getTime() > points[indexMax].getTime() is true
      maxPosition = generateMarkerPosition( points[indexMax], points[indexMax+1], output.end );
    else
      maxPosition = points[indexMax];
  } else {
    maxPosition = [points[points.length-1][0], points[points.length-1][1]];
  }

  return [ [ minPosition[1], minPosition[0] ], [ maxPosition[1], maxPosition[0] ] ];
}
