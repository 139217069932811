import React from "react";
import { Link } from "react-router-dom";

const FooterAuth = ( props ) => {
  return (
    <footer>
      <div className="widthmenu-social-icons">
        <ul>
          <li><a href="https://twitter.com/kronovisor"><i className="fa fa-twitter"></i></a></li>
          <li><a href="https://www.facebook.com/kronovisor"><i className="fa fa-facebook"></i></a></li>
          <li><a href="https://www.youtube.com/channel/UCcxFUiQrgibMZZyxjMfI-sg"><i className="fa fa-youtube-play"></i></a></li>
          <li><a href="https://www.linkedin.com/company/inar-group/"><i className="fa fa-linkedin"></i></a></li>
        </ul>
      </div>

      <div className="widthmenu-link-text">
     		<br></br>
     		{/*<a href="https://www.apple.com/la/ios/app-store" className="link-text2" target="_blank" rel="noopener noreferrer"><img src="img/appstore-apple-min.png" alt="app store" className="widthmenu-download"/></a>*/}
        <a href="https://play.google.com/store/apps/details?id=inargroupltd.kronovisor" className="link-text2" target="_blank" rel="noopener noreferrer"><img src="img/appstore-google-min.png" alt="app store" className="widthmenu-download"/></a>
      </div>
      <div className="widthmenu-link-text">
     		<Link to="help"> Help</Link> <br></br>
      	<Link to="about" className="link-text2"> About us</Link> <br></br>
        <Link to="contact-us" className="link-text2"> Contact us</Link>
      </div>
      <div className="widthmenu-link-text">
     		<Link to="terms-of-use"> Terms of use</Link> <br></br>
        <Link to="privacy-policy" className="link-text2"> Privacy Policy </Link> <br></br>
        <Link to="cookie-policy" className="link-text2"> Cookies Policy </Link>
      </div>
    </footer>
  );
};

export default FooterAuth;
