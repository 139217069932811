import React from "react";

const TermsOfUse = ( props ) => {
  let classContent = "simple-main-content";
  if ( props.isAuthenticated )
    classContent = "widthmenu-main-content";
  return (
    <div className={classContent}>
      <div className="fluid-container">
        <div className="page-section-policy">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <br></br>
            <h3 className="lead text-center">  Terms of use </h3>
            <hr></hr>

            <h5 className="lead"> KronoVisor®: Terms of Use </h5>

            <h5 className="lead"> Introduction </h5>
            <p>The KronoVisor® app and associated website (KronoVisor) is owned and operated by Inar Group Ltd a company registered in England and Wales with company number 11158233, registered office at Mansion House, Manchester Road, Altrincham,WA14 4RW,United Kingdom (we, us).  By using KronoVisor you agree to be bound by these terms (the Terms of Use) together with the privacy policy accessible in KronoVisor (the Privacy Policy). These Terms of Use and the Privacy Policy affect your legal rights and obligations so please read them carefully. If you do not agree to be bound by these Terms of Use and/or the Privacy Policy, do not use KronoVisor. If you have any questions, or otherwise need to contact us, you can do so at info@kronovisor.com.</p>
            <p>We reserve the right to update these Terms of Use from time to time at our discretion. If we do so, the updated version will be effective as soon as it is accessible. You are responsible for regularly reviewing these Terms of Use so that you are aware of any changes to them.</p>
            <p>You must be at least 18 years old to download KronoVisor and/or create an account on KronoVisor.</p>
            <p>You may only download KronoVisor in your territory where you are lawfully entitled to do so.</p>

            <h5 className="lead"> Registration </h5>
            <p>When you create an account  at KronoVisor, you need to provide certain information which we shall process in accordance with the terms of our Privacy Policy.  You are responsible for all activities that occur under your account.  You may only register on KronoVisor using your own details, and all information you provide to us on registration must be accurate and complete.</p>

            <h5 className="lead"> Fee </h5>
            <p>You can use KronoVisor free of charge, but if you wish to store your video content on KronoVisor, then a monthly fee is due in advance.  If at any time you fail to pay the total monthly fee when due, we may suspend your access to your video content on KronoVisor without notice to you.</p>
            <p>You can add devices to your KronoVisor account by following the steps on KronoVisor, although an additional fee may be payable.</p>

            <h5 className="lead"> Your video content </h5>
            <p>We encourage users to use their KronoVisor account to upload video content.  But, we have a number of rules.</p>
              <ul>
                <li>• Your mobile device should be placed on your dashboard to record the images in front of view.  Do be careful to ensure the view of the driver is not obscured by the device.</li>
                <li>• Your video content must be taken on a device owned by you, and you must be  operating the device</li>
                <li>• Your video content must not include any identifiable individual – you understand that we will hide any individual who is recognizable and any vehicle registration plate from your video content on KronoVisor</li>
                <li>• You must not record video content on private land without permission of the owner of that land.</li>
                <li>• Your video content must not contain any individual who is clearly under the age of 18, any nudity, violence or otherwise offensive images</li>
              </ul>
            <p>You understand and agree that your video content can be viewed by other users on KronoVisor, and that they may be able to see who has posted the video content.</p>
            <p>You further agree that we can use, reproduce, distribute, transfer and edit your video content for any purpose, and combine your video content with other video content for use within KronoVisor and otherwise including on our YouTube channel. We do not have to attribute video content to the user who provided that video content to KronoVisor.</p>

            <h5 className="lead"> Use of KronoVisor </h5>
            <p>You may use KronoVisor for your personal use only.  For example, you may view content and search for content but you must not sell access to content, or compile the content into a database which you offer for sale.</p>
            <p>You must not to (a) try to gain unauthorised access to KronoVisor or any networks, servers or computer systems connected to KronoVisor; (b) use KronoVisor in any manner that could damage, disable, overburden or impair our servers or networks or interfere with any other user’s use and enjoyment of KronoVisor; and/or (c) save to the extent expressly permitted by law and not capable of exclusion by law, copy, reproduce, redistribute, sell, create derivative works from, decompile, reverse engineer, or disassemble all or part of KronoVisor.</p>

            <h5 className="lead"> Advertisements </h5>
            <p>We accept no responsibility for adverts contained within KronoVisor. If you agree to purchase goods and/or services from any third party who advertises in KronoVisor, you do so at your own risk. The advertiser- not us-  is responsible for such goods and/or services and if you have any questions or complaints in relation to them, you should contact the advertiser.</p>
            <p>Liability and disclaimer – Your attention is particularly drawn to this section</p>
            <p>We warrant that KronoVisor will be of satisfactory quality, fit for purpose and as described.  Otherwise, KronoVisor is provided ‘as is’.  In particular, we do not warrant that KronoVisor will enable to you see any particular area at any particular time.  We also do not accept responsibility for the video content uploaded by our users.</p>
            <p>In any event, we shall only be liable to you for direct damages that arise as a direct result of the use of KronoVisor and that is caused by our failure to use reasonable skill and care.</p>
            <p>Nothing in these Terms of Use shall be construed as excluding or limiting our liability for death or personal injury caused by its negligence, for fraud or fraudulent misrepresentation or for any other liability which cannot be excluded by English law.</p>

            <h5 className="lead"> Availability </h5>
            <p>There may be occasions when access to KronoVisor may be interrupted, including for scheduled maintenance or upgrades, for emergency repairs, or due to failure of telecommunications links and/or equipment. You also acknowledge that KronoVisor may be subject to breaches of security and that the submission of content may not be secure.</p>
            <p>We reserve the right to remove any content or features from KronoVisor for any reason, without prior notice and/or to suspend or cease providing any services relating to KronoVisor without notice, and shall have no liability or responsibility to you in any manner whatsoever in such circumstances.</p>

            <h5 className="lead"> Unauthorised content </h5>
            <p>If you believe that any content infringes your copyrights, you may submit a notice to us including the following information: (a) a physical or electronic signature of a person authorised to act on behalf of the owner of any exclusive right that is allegedly infringed; (b) identification of the copyright work claimed to have been infringed; (c) identification of the material that is claimed to be infringing;  (d) information reasonably sufficient to enable us to contact you, including your name, email and/or postal address; (e) a statement that you have a good faith belief that use of the material on KronoVisor is not authorised by the copyright owner, its agent and/or the law; and (f) a statement that the information in the notice is accurate and under penalty of perjury, you are authorised to act on behalf of the owner of an exclusive right that is allegedly infringed.</p>
            <p>If you believe that any content breaches your rights of privacy, you may also submit a notice to us including the following information: (a) confirmation of your identity; and (b) identification of where on KronoVisor you believe that your rights of privacy have been breached.</p>
            <p>We shall deal with any notice received under this section promptly and with due care; and we ask that you assist us to do so by providing any other information we request.</p>

            <h5 className="lead"> Termination </h5>
            <p>We may suspend or delete your account on KronoVisor for any reason including if (a) you have breached any provision of these Terms of Use; (b) we cannot verify or authenticate any information you provide to us; and/or (c) we determine that your conduct could damage our reputation.</p>
            <p>If we delete your account (a) you must cease to use KronoVisor and delete it from your device; (b) you may not re-register under a different name; and (c) you acknowledge that your content may be deleted.</p>
            <p>You may terminate your account at any time by deleting KronoVisor, or by contacting us accordingly.</p>
            <p>If you have a paid monthly subscription account, we shall not provide a refund to you if we terminate your account for good cause.</p>

            <h5 className="lead"> General </h5>
            <p>These Terms of Use and the Privacy Policy (as amended from time to time) constitute the entire agreement between you and us concerning your use of KronoVisor. If any provision of these Terms of Use is held by a court of competent jurisdiction to be invalid or unenforceable, then such provision shall be construed, as nearly as possible, to reflect the intentions of the parties and all other provisions shall remain in full force. Our failure to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision.</p>
            <p>You consent to receive all communications including notices and other information from us electronically. We may provide all such communications by email, text or by posting them on KronoVisor.</p>
            <p>If you have any complaint or wish to raise a dispute under these Terms of Use or otherwise in relation to KronoVisor please follow this link http://ec.europa.eu/odr</p>
            <p>These Terms of Use shall be governed by and construed in accordance with English law and you agree to submit to the exclusive jurisdiction of the English Courts.</p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsOfUse;
