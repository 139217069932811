import { API } from "aws-amplify";

export async function getClusters( selection = null ) {
  const quadrants = new Set();
  let res = await API.get( "apiGateway", "/public/logs/cluster", { queryStringParameters: selection } );
  for ( let cluster of res.Items )
    quadrants.add( cluster );

  while ( res.LastEvaluatedKey ) {
    res = await API.get( "apiGateway", "/public/logs/cluster", { queryStringParameters: { ...selection, lekStart: res.LastEvaluatedKey.start, lekDeviceId: res.LastEvaluatedKey.deviceId } });
    for ( let cluster of res.Items )
      quadrants.add( cluster );
  }
  return Array.from( quadrants );
}

export async function getVideos( quadrant, selection ) {
  let res = await API.get( "apiGateway", "/public/logs", { queryStringParameters: { ...selection, quadrant } });
  let videos = res.Items;
  while ( res.LastEvaluatedKey ) {
    res = await API.get( "apiGateway", "/public/logs", { queryStringParameters: { ...selection, quadrant, lekStart: res.LastEvaluatedKey.start, lekDeviceId: res.LastEvaluatedKey.deviceId } });
    videos = videos.concat( res.Items );
  }
  return videos;
}
